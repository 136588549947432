.edit-modallll .save-bntnnnn {
    font-size: 16px;
    color: #FFF;
    background: #000;
    border: none ;
    padding: 5px 20px;
    border-radius: 5px;                                                                                      ;
}

.edit-modallll .main-btn-classs {
    margin: auto;
}