.copounmodall img.card-img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    
}

.copounmodall .image {
    width: 100%;
    height: 200px;
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copounmodall  .coupontitle{
    font-size: 20px;
    font-weight: 600;
    display: -webkit-box;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.copounmodall  .card-details{
    height: 60px;
}

.couptext{
    font-size: 16px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.codetxt{
    font-size: 14px;
    font-weight: 400;
    color: grey;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}