.my-account .back-text-holder p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  width: fit-content;
}

.my-account .product_table .span-holder {
  text-align: end;
}

.my-account .product_table .span-holder span {
  font-size: 12px;
  font-weight: 500;
  color: #323232;
}

.my-account .product_table tbody tr td {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  padding: 30px 19px;
  border: 0.5px solid #7070703d;
}

.my-account .product_table thead tr th {
  border: 0.5px solid #7070703d;
}


.my-account .product_table tbody tr .view-text {
  text-decoration: underline;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}

.my-account .product_table tbody tr .red-text {
  font-weight: 500;
  color: #ec0000;
}

.my-account .product_table tbody tr .green-text {
  font-weight: 500;
  color: #43aa00;
}

.my-account .product_table tbody tr .yellow-text {
  color: #deaa00;
  font-weight: 500;
}

.my-account .product_table tbody tr .return-text {
  color: #fb8600;
  font-weight: 500;
}

.my-account .product_table tbody tr .cancel-text {
  color: #000;
  font-weight: 500;
}
