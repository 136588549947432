.our-story {
  margin: 3% 0% 3% 0%;
}
.our-story .sub-text {
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.our-story .h5-quote {
  font-size: 24px;
  color: #359271;
  margin-top: 20px;
}

@media (min-width: 0px) and (max-width: 576px) {
  .our-story .h5-quote {
    font-size: 16px;
  }
  .our-story .sub-text {
    font-size: 12px;
    text-align: justify;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .our-story .h5-quote {
    font-size: 17px;
  }
  .our-story .sub-text {
    font-size: 12px;
    text-align: justify;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .our-story .h5-quote {
    font-size: 20px;
  }
}
