.browsmain-main {
  background-color: #359271;
  border-radius: 5px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 11px;
}

.browsmain-main .headingss {
  padding: 10px;
}

.browsmain-main .headingss .brow {
  font-size: 20px;
  color: #fff;
  margin: 0;
}

.browsmobile-section .acccord-head {
  width: 28px;
  height: 28px;
  font-size: 12px;
  background-color: #fff;
  color: #359271;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

/* .browsmobile-section .accordion-button::after {
  color: #fff;

  background-image: url('../../../../public/assets/Image/Icon/down-arrow2.png');
} */

.browsmobile-section .headname {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.browsmain-main button.accordion-button.collapsed {
  background-color: #20614b;
  box-shadow: none;
}

.browsmain-main .accordion-button:not(.collapsed) {
  background-color: #20614b !important;
  box-shadow: none !important;
}

.browsmain-main .accordion-button::after {
  filter: invert(1);
}

.browsmain-main .accordion-item {
  border: none;
}

.browsmobile-section .headname {
  font-size: 16px;
}

.browsmobile-section .cat-title {
  font-size: 16px;
}

.browsmobile-section .sub-p {
  margin-bottom: 4px;
  font-size: 16px;
  color: #359271;
}

.browsmobile-section .prod-nme {
  font-size: 13px;
  color: #000;
}

@media (min-width: 0px) and (max-width: 320px) {
  .browsmain-main {
    width: 100%;
  }

  .browsmain-main .headingss .brow {
    font-size: 16px;
  }

  .browsmobile-section .headname {
    font-size: 14px;
  }

  .browsmobile-section .cat-title {
    font-size: 14px;
  }

  .browsmobile-section .sub-p {
    margin-bottom: 4px;
    font-size: 14px;
  }

  .browsmobile-section .prod-nme {
    font-size: 11px;
  }
}

@media (min-width: 321px) and (max-width: 575px) {
  .browsmain-main {
    width: 95%;
  }

  .browsmobile-section .headname {
    font-size: 15px;
  }

  .browsmobile-section .cat-title {
    font-size: 16px;
  }

  .browsmobile-section .sub-p {
    margin-bottom: 4px;
    font-size: 15px;
  }

  .browsmobile-section .prod-nme {
    font-size: 12px;
  }

  .browsmain-main .headingss .brow {
    font-size: 17px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .browsmain-main {
    width: 80%;
  }

  .browsmobile-section .headname {
    font-size: 17px;
  }

  .browsmobile-section .cat-title {
    font-size: 17px;
  }

  .browsmobile-section .sub-p {
    margin-bottom: 4px;
    font-size: 16px;
  }

  .browsmobile-section .prod-nme {
    font-size: 13px;
  }

  .browsmain-main .headingss .brow {
    font-size: 18px;
  }
}
