.home-nav-tab {
  position: absolute;
  top: 23%;
  left: 19%;
  z-index: 999;
  background-color: #20614b;
  border-radius: 5px;
  width: 14%;
}
.home-nav-tab .nav-header {
  background-color: #359271;
  border-radius: 5px;
}
.home-nav-tab .nav-header h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0px 7px 25px;
}

.banner .home-nav-tab .navbar {
  position: content;
}

.home-nav-tab .nav-fafa-icons {
  background-color: #fff;
  border-radius: 25px;
  margin-right: 10px;
  padding: 8px;
  width: 29px;
  filter: sepia(1);
}
.home-nav-tab .nav-menu,
.home-nav-tab .nav-menu:hover,
.home-nav-tab .nav-menu:focus {
  color: #fff;
  font-size: 16px;
  margin-left: 5px;
}
.home-nav-tab .dropend {
  position: static;
  display: block;
  margin-left: 0;
  transition: all 0.2s;
}

.product-name a {
  color: #359271;
}

/* .home-nav-tab .dropend:hover .dropdown-menu {
  display: block;
  position: absolute;
  left: 101%;
  bottom: 0%;
  z-index: 300;
  visibility: visible;
  opacity: 1;
  transition: all 0.25s ease-in 0.3s !important;
} */

.home-nav-tab .dropend .dropdown-toggle::after {
  vertical-align: 0;
  float: right;
  margin-top: 8px;
}
.home-nav-tab .dropend .dropdown-menu[data-bs-popper] {
  margin-left: 15px;
}
.home-nav-tab .dropdown-menu {
  background-color: transparent;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  width: 538px;
  height: 370px;
  overflow-y: scroll;
  background-color: #fff;
  border: 0.2px solid #00000036;
  padding: 15px;
  transition: all 0.25s ease 0.1s;
}

.home-nav-tab .drop-suncategory .category-title {
  font-size: 15px;
  color: #000;
  margin-bottom: 0px;
  font-weight: 500;
}
.home-nav-tab .drop-suncategory .title-bd {
  border-bottom: 1px solid #359271;
  width: 20px;
}
.home-nav-tab .subcat-p {
  color: #359271;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 5px;
}
.home-nav-tab .product-name {
  color: #000;
  font-size: 12px;
  text-decoration: none;
}
.home-nav-tab .product-name:hover {
  color: #359271;
}

.home-nav-tab-mobile {
  position: absolute;
  top: 5%;
  left: 7%;
  z-index: 999;
  border-radius: 5px;
  width: 85%;
  border-radius: 5px;
}
.home-nav-tab-mobile .h6-title {
  color: #fff;
  background-color: #359271;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  padding: 10px;
  border-radius: 7px 7px 0px 0px;
}
.home-nav-tab-mobile .accordion-button {
  background-color: #359271;
  padding: 10px;
  color: white;
  font-size: 15px;
}
.home-nav-tab-mobile .accordion-button:focus {
  box-shadow: none;
}
.home-nav-tab-mobile .category-icon-mob {
  background-color: #fff;
  width: 25px;
  padding: 5px;
  border-radius: 50px;
  margin-right: 10px;
}
.home-nav-tab-mobile .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #359271;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.home-nav-tab-mobile .accordion-body {
  padding: 10px;
  background-color: #fff6f2;
}
.home-nav-tab-mobile .category-nav-h6-mob {
  font-size: 15px;
  font-weight: 600;
}
.home-nav-tab-mobile .sub-cat-p {
  font-size: 14px;
  color: #359271;
  margin-bottom: 0px;
}
.home-nav-tab-mobile .product-name {
  font-size: 12px;
  color: #000;
  text-decoration: none;
}
.home-nav-tab-mobile .accordion-header {
  text-decoration: none;
}
.home-nav-tab-mobile .accordion-item {
  border: 0px solid rgba(0, 0, 0, 0.125);
}
/* .home-nav-tab-mobile .accordion-button::after {
    background-image: url("../../Images/icon/down-arrow.png") !important;
    background-size:15px;
    transition: 0.3s;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../../Images/icon/down-arrow.png") !important;
    transform: rotate(0deg) !important;
    -webkit-transform: scaleX(1) !important;
    transform: scaleY(-1) !important;
    margin-top: -25px;
    transition: 0.3s;
} */

.home-nav-tab .category-next-icon {
  width: 15px;
  transform: rotate(270deg);
  float: right;
  margin-top: 10px;
}
.home-nav-tab .dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0px solid transparent;
  border-right: 0;
  border-bottom: 0px solid transparent;
  border-left: 0px solid;
}

.scale-in-hor-left {
  -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@media (max-width: 320px) {
  .banner .owl-theme .owl-nav {
    display: none;
  }
  .home-nav-tab {
    display: none;
  }
  .home-nav-tab-mobile .h6-title {
    font-size: 16px;
  }
  .home-nav-tab-mobile .accordion-button {
    font-size: 13px;
  }
  .home-nav-tab-mobile .category-nav-h6-mob {
    font-size: 13px;
  }
  .home-nav-tab-mobile .sub-cat-p {
    font-size: 12px;
  }
  .home-nav-tab-mobile .product-name {
    font-size: 11px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .banner .owl-theme .owl-nav {
    display: none;
  }
  .home-nav-tab {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 574px) {
  .banner .owl-theme .owl-nav {
    display: none;
  }
  .home-nav-tab {
    display: none;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .banner .owl-theme .owl-nav {
    display: none;
  }
  .home-nav-tab {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .banner .sec-2 .banner-h1 {
    font-size: 42px;
    padding-top: 65%;
  }

  .banner .sec-2 .banner-h2 {
    font-size: 25px;
  }

  .banner .home-product .product-card .img-set {
    height: 75px;
  }

  .banner .col-size {
    width: 50%;
  }
  .home-nav-tab {
    width: 26%;
    top: 15%;
    left: 15%;
  }
  .home-nav-tab .dropdown-menu {
    width: 400px;
    height: 370px;
  }
  .home-nav-tab .nav-header h6 {
    font-size: 14px;
  }
  .home-nav-tab .nav-menu,
  .home-nav-tab .nav-menu:hover,
  .home-nav-tab .nav-menu:focus {
    font-size: 13px;
  }
  /* .home-nav-tab {
    width: 20%;
  } */
  .home-nav-tab .nav-fafa-icons {
    width: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  /* .home-nav-tab {
    width: 25%;
  } */
  .home-nav-tab .dropdown-menu {
    width: 500px;
    height: 370px;
  }
  .banner .owl-theme .owl-nav {
    display: none;
  }
  .home-nav-tab .nav-header h6 {
    font-size: 14px;
  }
  .home-nav-tab .nav-menu,
  .home-nav-tab .nav-menu:hover,
  .home-nav-tab .nav-menu:focus {
    font-size: 13px;
  }
  .home-nav-tab {
    width: 25%;
    top: 16%;
    left: 8%;
  }
  .home-nav-tab .nav-fafa-icons {
    width: 25px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  /* .home-nav-tab {
    width: 25%;
  } */
  .banner .owl-theme .owl-nav {
    display: none;
  }
  .home-nav-tab .nav-header h6 {
    font-size: 14px;
  }
  .home-nav-tab .nav-menu,
  .home-nav-tab .nav-menu:hover,
  .home-nav-tab .nav-menu:focus {
    font-size: 13px;
  }
  .home-nav-tab {
    width: 25%;
    top: 16%;
    left: 14%;
  }
  .home-nav-tab .nav-fafa-icons {
    width: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .home-nav-tab {
    width: 20%;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .home-nav-tab {
    width: 20%;
  }
}
@media (min-width: 1600px) and (max-width: 1800px) {
  .home-nav-tab {
    width: 20%;
  }
}

@media (min-width: 2000px) and (max-width: 3000px) {
  .home-nav-tab {
    top: 14%;
    left: 27%;
  }
}

/* end banner section style  */
