/* start Common card style  */
.card-section .card {
  border: 0px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
}

.card-section {
  margin: 0px 10px 25px 10px;
  position: relative;
}

.card-section .card-set {
  border: 1px solid #7070709c;
  transition: 0.4s;
  border-radius: 5px;
  padding: 10px;
}

.card-section .card-set:hover {
  background-color: #35927126;
}

.card-section .card-body {
  max-height: 100%;
  height: auto;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card-set .product-img {
  transition: 0.4s;
  padding-top: 0px;
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.card-set button.btn.btn-primary {
  background-color: transparent;
  border: none;
}

.card-set:hover .product-img {
  transform: scale(1.1);
  transform-origin: 50% 50%;
  transition: 0.4s;
}

.offer-card-p {
  font-size: 12px;
  color: white;
  background-color: #20614b;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 15px;
}

.card-section .details {
  margin-top: 20px;
}

.card-section .details .product-name {
  font-size: 16px;
  color: #000;
  margin-bottom: 9px;
}


.card-section .details .details-link {
  text-decoration: none;
  color: #000;
}

.card-section .details .product-price {
  font-size: 15px;
  margin-right: 5px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0px;
}

.card-section .details .product-price-disabled {
  font-size: 15px;
  margin-right: 5px;
  font-weight: 500;
  color: #b2b2b2;
  margin-bottom: 0px;
}

.card-section .details .product-price-disabled .disablesub {
  text-decoration: line-through;
}

.card-section .details .product-price-disabled .offerprice {
  color: #20614b;
  margin-left: 10px;
}

.product-price2 {
  font-size: 18px;
  color: #b2b2b2;
  margin-bottom: 0px;
  text-decoration-line: line-through;
}

.product-price3 {
  font-size: 18px;
  color: #359271;
  margin-bottom: 0px;
  margin-left: 10px;
}

.fa-solid {
  color: #359271;
}

.fa-heart {
  font-size: 25px;
}

.card-section .wishlist-div {
  text-align: right;
  display: none;
}

.card-section .card:hover .wishlist-div {
  display: block;
}

.card-section .details .shopping-cart-img {
  width: 25px;
}

.card-section .details .shopping-align {
  float: right;
}

.shopping-img-align {
  float: right;
}

.card-section .details .color1 {
  background-color: #000;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-left: 5px;
}

.card-section .details .color2 {
  background-color: #9f70ff;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-left: 5px;
}

.card-section .details .color3 {
  background-color: #34cc67;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-left: 5px;
}

.card-section .details .color4 {
  background-color: #ffd501;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-left: 5px;
}

.card-section .details .color5 {
  background-color: #ff1313;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-left: 5px;
}

.add-cart-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
}

.add-cart-box .minus {
  background-color: #f6f6f6;
  border-right: 1px solid #ccc;
  padding: 0px 9px;
  font-size: 17px;
  border-radius: 5px;
}

.add-cart-box .add {
  background-color: #f6f6f6;
  border-left: 1px solid #ccc;
  padding: 0px 9px;
  font-size: 17px;
  border-radius: 5px;
}

.add-cart-box .quantity {
  background-color: #fff;
  padding: 0px 15px;
  font-size: 17px;
}

.owl-carousel .owl-nav {
  position: absolute;
  right: 0;
  bottom: 103%;
}

.owl-carousel .owl-nav button {
  display: inline-block;
  width: 30px;
  height: 28px;
  background-color: #000 !important;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 5px;
  color: #000;
}

img {
  cursor: pointer;
}

.btn {
  cursor: pointer;
}

.btn:focus {
  box-shadow: none !important;
}

@media (max-width: 319px) {
  .card-section .details .product-name {
    font-size: 11px;
  }

  .card-section .details .product-price {
    font-size: 11px;
  }

  .card-section .details .shopping-cart-img {
    width: 20px;
  }

  .card-section .card-body {
    height: auto;
  }

  .card-set .product-img {
    /* height: 100px; */
  }

  .card-section .details .color1 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }

  .card-section .details .color2 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }

  .card-section .details .color3 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }

  .card-section .details .color4 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }

  .card-section .details .color5 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }
}

@media (min-width: 320px) and (max-width: 484px) {
  .card-section .details .product-name {
    font-size: 13px;
  }

  .card-section .details .product-price {
    font-size: 13px;
  }

  .card-section .details .shopping-cart-img {
    width: 20px;
  }

  .card-section .card-body {
    height: auto;
  }

  .card-set .product-img {
    /* height: 120px; */
  }

  .card-section .details .color1 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }

  .card-section .details .color2 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }

  .card-section .details .color3 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }

  .card-section .details .color4 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }

  .card-section .details .color5 {
    width: 8px;
    height: 8px;
    margin-left: 2px;
  }
}

@media (min-width: 380px) and (max-width: 480px) {
  .card-section .details .color1 {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }

  .card-section .details .color2 {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }

  .card-section .details .color3 {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }

  .card-section .details .color4 {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }

  .card-section .details .color5 {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }
}

@media (min-width: 485px) and (max-width: 574px) {
  .card-section .details .product-name {
    font-size: 15px;
  }

  .card-section .details .product-price {
    font-size: 15px;
  }

  .card-section .card-body {
    height: auto;
  }

  .card-set .product-img {
    height: 150px;
  }

  .card-section .details .color1 {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }

  .card-section .details .color2 {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }

  .card-section .details .color3 {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }

  .card-section .details .color4 {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }

  .card-section .details .color5 {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .card-section .card-body {
    /* height: 220px; */
  }

  .card-set .product-img {
    /* height: 150px; */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .card-set .product-img {
    /* height: 150px; */
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .card-set .product-img {
    /* height: 150px; */
  }

  .card-section .card-body {
    /* height: 160px; */
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .card-set .product-img {
    /* height: 170px; */
  }

  .card-section .card-body {
    /* height: 190px; */
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .card-set .product-img {
    /* height: 170px; */
  }

  .card-section .card-body {
    /* height: 200px; */
  }
  .card-section .details .product-name {
    font-size: 15px;
  }
  .card-section .details .product-price {
    font-size: 15px;
  }
  .card-section .details .product-price-disabled {
    font-size: 15px;
  }
  .card-section .details .shopping-cart-img {
    width: 20px;
  }
  .card-section .details {
    margin-top: 10px;
  }
}

/* end Common card style  */