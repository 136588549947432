@import url("https://fonts.googleapis.com/css2?family=Arial:wght@400;500;600;700;800;900&display=swap");
body {
  margin: 0px;
  padding: 0px;
  font-family: "Arial" !important;
}

.title {
  font-family: "Arial" !important;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}



.product-text {
  font-size: 15px;
  font-family: "Roboto" !important;
  text-align: center;
  margin-bottom: 0px;
}

.subscribe-btn {
  background: #359271 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  border: 0px;
  font-size: 16px;
  width: 168px;
  height: 38px;
}

.view-all {
  font-size: 14px;
  color: #0f3768;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
}

.frequently {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  font-family: "Roboto" !important;
}

.weight {
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  font-family: "Roboto" !important;
}

.product-detail {
  font-size: 13px;
  color: #0f3768;
  font-family: "Roboto" !important;
  font-weight: 500;
}

.title-border {
  text-align: center;
  width: 45px;
  height: 2px;
  background: #359271 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  margin-right: auto;
  margin-left: auto;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}

a {
  font-size: 12px;
  color: #000;
}

.breadcrumb-item.active {
  font-size: 12px;
  color: #000;
}

li.breadcrumb-item {
  color: #000;
  font-size: 12px;
}

.multicolors .form-check-inline {
  margin-right: 0;
  margin-left: 5px;
}

.multicolors .bt1 .form-check-input {
  background: #ff00ee;
  border: none;
}
.multicolors .bt2 .form-check-input {
  background: #b71c1c;
  border: none;
}
.multicolors .bt3 .form-check-input {
  background: #34cc67;
  border: none;
}

.multicolors .bt4 .form-check-input {
  background: #01a8ff;
  border: none;
}
.multicolors .bt5 .form-check-input {
  background: #ff1313;
  border: none;
}

.multicolors .form-check-input:checked {
  box-shadow: none;
  border: 2px solid black;
}

.multicolors .bt1 .form-check-input:checked {
  background: #ff00ee;
}
.multicolors .bt2 .form-check-input:checked {
  background: #b71c1c;
}
.multicolors .bt3 .form-check-input:checked {
  background: #34cc67;
}

.multicolors .bt4 .form-check-input:checked {
  background: #01a8ff;
}

.back-text-holder {
  width: fit-content;
} 

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #198754 !important;
}
.multicolors .bt5 .form-check-input:checked {
  background: #ff1313;
}

h3.hot-title {
  font-size: 30px;
  font-weight: 700;
}

/* bredcumb ccc */
.link-bredcumb {
  color: #000;
}

@media (min-width: 0px) and (max-width: 767px) {
  .subscribe-btn {
    font-size: 14px;
    width: 93px;
    height: 36px;
  }
}

@media (min-width: 0px) and (max-width: 485px) {
  .title {
    font-size: 20px;
  }
  h3.hot-title {
    font-size: 20px;
  }
  .local-text {
    font-size: 14px;
  }

  .product-text {
    font-size: 15px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .title {
    font-size: 22px;
  }
  h3.hot-title {
    font-size: 22px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .title {
    font-size: 24px;
  }
  h3.hot-title {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .title {
    font-size: 26px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .title {
    font-size: 28px;
  }
}
