/* start our partner style  */
.our-partner {
    margin: 8% 0%;
}

.our-partner .main-div {
    background-color: #e1fff4;
    padding: 0% 10%;
    border-radius: 5px;
}

.our-partner .partners-img {
    width: 85%;
}

/* end our partner style  */