.happy-clients-section {
  margin: 3% 0% 3% 0%;
}
.happy-clients-section .client-div {
  height: 255px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
}

.happy-clients-section .client-div .star-holder {
  display: flex!important;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.happy-clients-section .happy-clients_mySwiper {
  padding-top: 15px;
  padding-bottom: 15px;
}


.happy-clients-section .client-div .clints {
  display: flex;
  align-items: center;
}
.happy-clients-section .client-div .client-img {
  width: 79px;
  height: 75px;
  border-radius: 10px;
}
.happy-clients-section .client-div .client-name {
  font-size: 20px;
  font-weight: 600;
  padding-left: 20px;
}
.happy-clients-section .client-div .client-review {
  font-size: 14px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.happy-clients-section .client-div .star-svg {
  width: 20px;
  /* height: 20px; */
}

@media (max-width: 320px) {
  .happy-clients-section .client-div .client-name {
    font-size: 16px;
    margin-top: 20px;
  }
  .happy-clients-section .client-div .client-img {
    width: 100%;
    height: 50px;
  }
  .happy-clients-section .client-div {
    height: 226px;
    padding: 15px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .happy-clients-section .client-div .client-name {
    font-size: 16px;
    margin-top: 20px;
  }
}
