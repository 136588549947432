.become-a-seller {
    /* margin: 7% 0; */
    margin-bottom: 12%;
}

.become-a-seller .image-seller .become-img {
    height: 405px;
    border-radius: 10px;
    /* width: 100%; */
    width: 936px;
}

.become-a-seller .form-seller {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 40px 57px;
    margin-top: -139px;
    opacity: 0.95;
}

.become-a-seller .form-seller h2 {
    font-size: 18px;
    font-weight: bold;
}

.become-a-seller .form-seller .form-label {
    font-size: 13px;
}

.become-a-seller .form-seller .form-control {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.800000011920929px solid #7D7D7D;
    border-radius: 5px;
    color: #B1B1B1;
    font-size: 12px;
    height: 38px;
}

.become-a-seller .form-seller .form-control::placeholder {
    color: #B1B1B1;
    font-size: 12px;
}

.become-a-seller .form-seller .form-control:focus {
    box-shadow: none;
}

.become-a-seller .form-seller .btn-sub {
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFF;
    width: 149px;
    height: 38px;
    font-size: 14px;
    border: none;
}

.become-a-seller .form-seller .btn-sub:focus {
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFF;
    width: 149px;
    height: 38px;
    font-size: 14px;
    border: none;
}

@media(min-width:0px) and (max-width:767px) {
    .become-a-seller .form-seller {
        padding: 27px;
        margin-top: -84px;
    }

    .become-a-seller .image-seller .become-img {
        height: 250px;
        border-radius: 10px;
        width: 100%;
        object-fit: cover;
    }

}

@media(min-width:0px) and (max-width:321px) {
    .become-a-seller {
        margin-bottom: 40%;
    }
}

@media(min-width:321px) and (max-width:486px) {
    .become-a-seller {
        margin-bottom: 35%;
    }
}

@media(min-width:486px) and (max-width:577px) {
    .become-a-seller {
        margin-bottom: 25%;
    }
}

@media(min-width:578px) and (max-width:767px) {
    .become-a-seller {
        margin-bottom: 25%;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .become-a-seller .image-seller .become-img {
        height: 335px;
        border-radius: 10px;
        width: 100%;
        object-fit: cover;
    }

    .become-a-seller .form-seller {
        padding: 40px 19px;
        margin-top: -95px;

    }

    .become-a-seller {
        margin-bottom: 20%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .become-a-seller .image-seller .become-img {
        height: 335px;
        border-radius: 10px;
        /* width: 100%; */
        width: 786px;
        object-fit: cover;
    }

    .become-a-seller {
        margin-bottom: 15%;
    }

}