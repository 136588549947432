.feature-section .abc {
  display: none !important;
}

.feature-section .bcd {
  display: block !important;
}
.feature-section .pagin {
  /* margin-left: auto; */
  display: flex;
  justify-content: center;
}
.feature-section .card-section .btn-primary{
  background-color: transparent !important;
  border: 0px !important;
}
.feature-section .view-all-btn {
  background-color: transparent;
  color: #000;
  width: 130px;
  height: 31px;
  font-size: 13px;
  border-radius: 10px;
  border: none;
  font-weight: 700;
  text-decoration: underline;
  font-size: 14px;
}

.feature-section .page-link {
  color: black !important;
}

.feature-section .active>.page-link, .page-link.active {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: none !important;
  border: 1px solid #000;
}

@media(min-width:0px)and (max-width: 575px){
.feature-section .page-link {
  font-size: 10px;
  padding: 5px 10px;
}
}
