/* desktop css start */
.product-details-dropdown .product-details-nav-tab {
  position: sticky;
  top: 25%;
  left: 11%;
  z-index: 999;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 85%;
  background-color: #fff;
}

.product-details-dropdown .dropdown-toggle {
  white-space: nowrap;
  width: 100%;
  text-align: start;
  color: black;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffff;
  border: none;
  padding: 12px;
}

.product-details-dropdown .btn-primary:hover,
.product-details-dropdown .btn-primary:active,
.product-details-dropdown .btn-primary.active {
  background-color: #fff;
  color: #000;
}

.product-details-dropdown .btn.show {
  white-space: nowrap;
  width: 100%;
  text-align: start;
  color: black;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff6f2;
  border: none;
}

.product-details-dropdown .dropend .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 20px;
}

.product-details-dropdown .dropdown-item:hover {
  color: red;
}

.product-details-dropdown .dropdown-item:active {
  background-color: #fff;
}
.product-details-dropdown .dropdown-item {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
/* mobile view accordian css */
.product-details-dropdown .mobile-view .mobile-accordian {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.4000000059604645px solid #707070;
  border-radius: 4px;
  opacity: 1;
}

.product-details-dropdown .cat-div {
  background: #359271 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 13px 17px;
  margin-bottom: 5px;
}

.product-details-dropdown .cat-div h5 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}

.product-details-dropdown
  .mobile-view
  .mobile-accordian
  .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff6f2;
}

.product-details-dropdown
  .mobile-view
  .mobile-accordian
  .accordion-button:focus {
  box-shadow: none;
}

.product-details-dropdown .pen-icons {
  width: 27px;
  height: 27px;
  margin-right: 10px;
  filter: hue-rotate(45deg);
}

.product-details-dropdown .mobile-view .mobile-accordian .links a {
  font-size: 14px;
  color: #000;
}

.product-details-dropdown .mobile-view .mobile-accordian .accordion-button {
  font-size: 14px;
  font-weight: 400;
}

.product-details-dropdown .mobile-view {
  display: none;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .product-details-nav-tab .dropend .dropdown-menu[data-bs-popper] {
    top: 79px;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer);
    left: 75px;
  }

  .product-details-dropdown .desktop-view {
    display: none;
  }

  .product-details-dropdown .mobile-view {
    display: block;
  }

  .product-details-dropdown .cat-div h5 {
    font-size: 15px;
  }

  .product-details-dropdown .cat-div {
    margin-bottom: 0px;
  }
}
