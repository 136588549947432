.product-feature .title-div h2 {
  font-size: 28px;
  font-weight: 700;
}

.product-feature .pro-flex .rate-star {
  width: 12px;
}

.product-feature .pro-flex .star-h6 {
  font-size: 14px;
  font-weight: 700;
  padding-top: 10px;
}

.product-feature .product-information .pro-flex .review-span {
  font-size: 14px;
  color: #838388;
  font-weight: 500;
}



.product-feature .number-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}


.product-feature .product-information .title-div .priceeee {
  font-size: 14px;
  text-align: justify;
  text-decoration: line-through;
}



.product-feature .product-information .title-div p {
  font-size: 14px;
  text-align: justify;
}

.product-feature .product-information .title-div .read-more {
  border: 0px;
  background: none;
  font-size: 13px;
  font-weight: 700;
}

.product-feature .product-information .add-to-cart-btn {
  font-size: 14px;
  background-color: #212121;
  color: white;
  padding: 7px 20px;
  border: none;
  border-radius: 5px;
}

.product-feature .add-cart-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
}

.choose-color-div .shopping-align .color-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
  /* border: 1px solid red; */
}

.choose-color-div .shopping-align .color-circle.selected {
  width: 27px;
  height: 27px;
  border: 2px solid red;
  /* Apply border if variant is selected */
  opacity: 1;
  /* Adjust opacity if variant is selected */
}

.choose-color-div .shopping-align .color2 {
  background-color: #9F70FF;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin-left: 5px;
  cursor: pointer;
}

.choose-color-div .shopping-align .color3 {
  background-color: #34CC67;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin-left: 5px;
  cursor: pointer;
}

.choose-color-div .shopping-align .color4 {
  background-color: #FFD501;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin-left: 5px;
  cursor: pointer;
}

.choose-color-div .shopping-align .color5 {
  background-color: #FF1313;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  margin-left: 5px;
  cursor: pointer;
}

.choose-color-div .choose-p {
  font-size: 14px;
  color: #000;
}

.product-feature .add-cart-box .minus {
  background-color: #F6F6F6;
  border-right: 1px solid #ccc;
  padding: 0px 9px;
  font-size: 17px;
  border-radius: 5px;
}

.product-feature .add-cart-box .add {
  background-color: #F6F6F6;
  border-left: 1px solid #ccc;
  padding: 0px 9px;
  font-size: 17px;
  border-radius: 5px;
}

.product-feature .product-information .title-div {
  border-bottom: 1px solid #707070;
}

.product-feature .table-responsive {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 302px;
}

.product-feature ::-webkit-scrollbar {
  width: 4px;
}

.product-feature .card {
  overflow: hidden;
}

.product-feature .card:hover .product-img-small {
  transform: scale(1.1);
  transform-origin: 50% 50%;
  transition: 0.4s;
}

/* Track */
.product-feature ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.product-feature ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.product-feature ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.product-feature .sub-product-div {
  height: 70px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 8px;
}


@media screen and (min-width: 0px) and (max-width: 768px) {

  .product-feature .title-div h2 {
    font-size: 18px;

  }

  .product-feature .product-information .pro-flex .review-span {
    font-size: 12px;
  }

  .product-feature .pro-flex .star-h6 {
    font-size: 12px;
  }

  .product-feature .product-information .title-div p {
    font-size: 13px;
  }

  .product-feature .product-information .cart-btn-div .add-cart-btn {
    font-size: 12px;
  }

  .choose-color-div .choose-p {
    font-size: 12px;
  }

  .product-feature .table-responsive {
    height: 233px;
  }

  .product-information {
    margin-top: 5%;
  }

}


/* New CSS */

.cctv-innerpage {
  margin-top: 10%;
}


.cctv-innerpage .cards-section .cards {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000078;
  opacity: 1;
  min-height: 281px;
  height: 100%;
}

.cctv-innerpage .cards-section {
  margin: 35px 20px;
}

.cctv-innerpage .cards-section .inner-card {
  padding: 9% 0% 5% 20%;
}

.cctv-innerpage .cards-section .inner-card .boxes {
  background-color: #ed1b24;
  height: 6px;
  width: 6px;
  left: 11%;
}

.cctv-innerpage .cards-section .inner-card .black-line {
  width: 1.5px;
  height: 41px;
  background-color: #bdbdbd;
  margin: 0px 2px;
}

.cctv-innerpage .cards-section .inner-card .onsite {
  margin-top: -6px;
}

.cctv-innerpage .cards-section .inner-card .onsite .scale {
  padding: 3px 0px;
}

.cctv-innerpage .card-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: auto;
  margin-left: auto;
  border-radius: 10px;
  border: 1px solid #707070;
}

.cctv-innerpage .card-sec:hover .product-img {
  transform: scale(1.1);
}

.cctv-innerpage .card-sec:hover {
  background-color: #f0534008;
}

.cctv-innerpage .card-sec .wishlist-div {
  text-align: end;
  padding: 15px 15px 0px 0px;
  opacity: 0;
}

.cctv-innerpage .card-sec:hover .wishlist-div {
  opacity: 1;
}

.cctv-innerpage .card-sec .product-detail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
}


/* .cctv-innerpage .card-sec .product-img {
  width: 200px;
  height: 123px;
  object-fit: contain;
  aspect-ratio: 1/3;
  transition: transform 0.5s;
} */

.cctv-innerpage .card-sec .product-img {
  width: 80%;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
  transition: transform 0.5s;
}
.cctv-innerpage .mySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0px 35px 0px;
  margin-left: 2%;
}

.cctv-innerpage .camera-imgs {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  padding: 3% 0;
  border-radius: 10px;
  border: 1px solid #707070;
  margin: 8px;
  padding: 5px;
}

.cctv-innerpage .camera-imgs .inner-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.cctv-innerpage .main-slider-cctv {
  position: relative;
}

.cctv-innerpage .main-slider-cctv .arrow-back,
.main-slider-cctv .arrow-next {
  position: absolute;
  bottom: -80px;
  left: 33px;
  right: 83px;
  cursor: pointer;
}

.cctv-innerpage .IR-hover-sec:hover {
  background: #e5e5e5;
  opacity: 1;
}

.cctv-innerpage .IR-hover-sec {
  padding: 10px 0px 10px 35px;
  margin-right: 5%;
}

.cctv-innerpage .cards-section .cards .surv-text {
  margin-top: 16px;
}

.product-feature .camera-slider {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 322px;
}

/* ***********media query start ***************** */
@media (min-width:0px) and (max-width:320px) {
  .product-feature {
    margin: 24px 0px;
  }
/* 
  .cctv-innerpage .card-sec .product-detail {
    height: 211px;
  } */

  .cctv-innerpage .card-sec .product-img {
    /* width: 150px; */
  }

  .cctv-innerpage {
    margin-top: 23%;
  }

  .cctv-innerpage .mySwiper {
    padding: 10px 0px 11px 0px;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .camera-imgs {
    margin: 10px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next::before {
    right: 17px;
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -6px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .surv-text {
    margin-top: 11px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    display: none;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    display: none;
  }

  .product-feature .camera-slider {
    height: 253px;
  }
}


@media (min-width:321px) and (max-width:485px) {
  .product-feature {
    margin: 24px 0px;
  }

  /* .cctv-innerpage .card-sec .product-detail {
    height: 211px;
  } */

  .cctv-innerpage .mySwiper {
    padding: 15px 0px 15px 0px;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .cards-section .inner-card {
    padding: 9% 0% 5% 26%;
  }

  .cctv-innerpage .camera-imgs {
    margin: 10px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 16%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next::before {
    right: 0px;
  }

  /* .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  } */

  .cctv-innerpage .card-sec .product-img {
    /* width: 160px; */
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 34px;
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -9px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -9px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .surv-text {
    margin-top: 14px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    display: none;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    display: none;
  }

  .product-feature .camera-slider {
    height: 294px;
  }
}

@media (min-width:486px) and (max-width:575px) {
  .product-feature {
    margin: 24px 0px;
  }

  /* .cctv-innerpage .card-sec .product-detail {
    height: auto;
  } */


  .cctv-innerpage .cards-section .inner-card {
    padding: 4% 0% 5% 25%;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 16%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  /* .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  } */

  .cctv-innerpage .card-sec .product-img {
    /* width: 165px; */
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 39px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    display: none;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    display: none;
  }

  .product-feature .camera-slider {
    height: 253px;
  }
}

@media (min-width:576px) and (max-width:767px) {
  .product-feature {
    margin: 24px 0px;
  }

  /* .cctv-innerpage .card-sec .product-detail {
    height: 211px;
  } */

  .cctv-innerpage .cards-section .inner-card {
    padding: 6% 0% 5% 25%;
  }


  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 13%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back::after {
    left: -32px;
  }

  /* .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  } */

  .cctv-innerpage .card-sec .product-img {
    /* width: 165px; */
  }

  .cctv-innerpage .cards-section .inner-card .black-line {

    height: 40px;

  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    /* top: -78px; */
    display: none;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    /* top: -79px;
      left: 369px; */
    display: none;
  }

  .product-feature .camera-slider {
    height: 253px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product-information {
    margin-top: 14%;
  }

  .cctv-innerpage .cards-section .inner-card {
    padding: 9% 0% 5% 10%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin-top: 4%;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    /* top: -78px; */
    display: none;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    /* top: -79px;
      left: 369px; */
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cctv-innerpage .camera-imgs .inner-img {
    width: 100%;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    /* top: -78px; */
    display: none;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    /* top: -79px;
      left: 369px; */
    display: none;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .cctv-innerpage .camera-imgs .inner-img {
    width: 100%;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back {
    /* top: -78px; */
    display: none;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next {
    /* top: -79px;
      left: 369px; */
    display: none;
  }
}