.innertxt-section .innertxt-main .txtdate {
  padding-top: 15px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #949494;
  opacity: 1;
}
.innertxt-section .innertxt-main .txthead {
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.innertxt-section .innertxt-main .txtcont {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #5d5d5d;
  opacity: 1;
  text-align: justify;
}

@media (min-width: 0px) and (max-width: 767px) {
  .innertxt-section .innertxt-main .txtdate {
    padding-top: 15px;
    font-size: 11px;
    line-height: 13px;
  }
  .innertxt-section .innertxt-main .txthead {
    font-size: 16px;
    line-height: 15px;
  }

  .innertxt-section .innertxt-main .txtcont {
    font-size: 13px;
  }
}
