/* start top-header section style  */
.top-header .background {
  background-color: #212121;
  padding: 3px;
}

.top-header .navbar .phone-no {
  color: white;
  font-size: 15px;
  margin-bottom: 0px;
  cursor: pointer;
}

.top-header .navbar .form-select option:hover {
  color: black;
  background-color: #f1f1f1;
}

.top-header .nav .option-height {
  height: 50px;
}

.top-header .container .nav .nav-item-text {
  color: white;
  font-size: 15px;
  margin-top: 3px;
}

.top-header .container .nav .nav-item .nav-link {
  padding: 0px 3px;
}

.top-header .log-in-btn {
  color: white;
  font-size: 15px;
  margin-bottom: 0px;
  cursor: pointer;
}

.top-header .circleuser {
  color: #fff;
  font-size: 15px;
}

@media (max-width: 991px) {
  .top-header .background {
    padding: 0px;
  }

  .top-header .navbar .phone-no {
    font-size: 12px;
  }

  .top-header .container .nav .nav-item-text {
    font-size: 12px;
    margin-top: 5px;
  }
}

/* end top-header section style  */

/* start logo section style  */
.head-logo .background {
  background-color: #fff6f2;
  padding: 10px 0px;
}

.head-logo .background .logo-img {
  /* width: 213px; */
  height: 60px;
  margin-top: 5px;
}

.head-logo .search .input-group {
  padding: 4px;
  background-color: white;
  border-radius: 25px;
  width: 100%;
  margin: auto;
  border: 1px solid #bdbdbd;
}

.head-logo .search .search-btn {
  background-color: #359271;
  color: #fff;
  border-radius: 25px;
  border: 0px;
  font-size: 18px;
  padding-right: 20px;
  padding-left: 20px;
  font-weight: 500;
}

.head-logo .search .form-control {
  border: 0px solid #ced4da;
  font-size: 18px;
  color: black;
  font-weight: 400;
  border-radius: 25px;
}

.head-logo .search .form-control:focus {
  box-shadow: none;
}

.head-logo .background .d-flex .icon-space {
  margin-left: 20px;
}

.head-logo .background .d-flex .icon-img {
  width: 20px;
  height: 20px;
  /* padding: 16px; */
}

.head-logo .background .d-flex .heart-bg {
  background-color: #ffffff;
  border: 1px solid #bdbdbd;
}

.head-logo .background .d-flex .badges-main {
  background-color: white;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  outline-offset: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-logo .background .d-flex .badges-main .badges-img {
  /* background-image: url("../../assets/icon/inquiry.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.head-logo .background .d-flex .badges-main .bg-danger1 {
  background-color: #359271;
  font-size: 10px;
  padding: 0px 4px;
}

.head-logo .background .d-flex .badges-main .start {
  left: 77%;
}

.head-logo .background .d-flex .badges-main .top {
  top: 27%;
}

.head-logo .background .d-flex .badges-main2 {
  background-color: #fff;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin-left: 15px;
  border: 1px solid #bdbdbd;
  outline-offset: -4px;
  margin-right: 25px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.head-logo .background .d-flex .badges-main2 .badges-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.head-logo .background .d-flex .badges-main2 .bg-danger1 {
  background-color: #359271;
  font-size: 10px;
  padding: 0px 4px;
}

.head-logo .background .d-flex .badges-main2 .start {
  left: 92%;
}

.head-logo .background .d-flex .badges-main2 .top {
  top: 27%;
}

.head-logo .background .search ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c5c5c5;
  opacity: 1;
  /* Firefox */
  padding-left: 10px;
}

.head-logo .background .search-bar-box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-logo .offcanvas-btn:focus {
  outline: 0;
  box-shadow: 0px 0px 0px 0px;
}

.inquiry-offcanvas .title {
  font-size: 25px;
  font-weight: 700;
}

.inquiry-offcanvas .sub-title {
  font-size: 18px;
}

.inquiry-offcanvas .offcanvas-header {
  border-bottom: 1px solid #a8a8a8;
}

.inquiry-offcanvas .bd-bottom {
  border-bottom: 1px solid #a8a8a8;
  padding: 10px;
}

.inquiry-offcanvas .inquiry-btn,
.inquiry-offcanvas .inquiry-btn:hover {
  background-color: #a07af8;
  color: white;
  font-size: 14px;
  width: 100%;
  border-radius: 20px;
}

.inquiry-offcanvas .overflow-canvas {
  overflow-y: scroll;
  /* height: 373px; */
  height: 70%;
  overflow-x: hidden;
}

.inquiry-offcanvas .card-set {
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  height: 157px;
}

.inquiry-offcanvas .card-set .img-div {
  height: 157px;
  width: 100%;
  object-fit: cover;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  border-right: 1px solid #b7b7b7;
}

.inquiry-offcanvas .card-set .img-div:hover .inquiry-img {
  overflow: hidden;
  box-sizing: border-box;
  transition: 0.4s;
  transform: scale(1.1);
}

.inquiry-offcanvas .card-set .img-div .inquiry-img {
  transition: 0.4s;
  width: 45%;
}

.inquiry-offcanvas .checkbox-div {
  width: 100%;
  height: 157px;
  max-height: 100%;
  object-fit: cover;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inquiry-offcanvas .checkbox-div .checkbox:checked {
  background-color: #767676;
  border-color: #767676;
}

.inquiry-offcanvas .row-set {
  width: 100%;
  height: 157px;
  max-height: 100%;
  object-fit: cover;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inquiry-offcanvas .card-set .select-product-div .pro-name {
  font-size: 16px;
  font-weight: 800;
  padding-top: 15%;
}

.inquiry-offcanvas .card-set .select-product-div .add-btn {
  font-size: 20px;
  background-color: black;
  color: white;
  font-weight: 600;
  border-radius: 50px;
  padding: 0px 11px;
}

.inquiry-offcanvas .card-set .select-product-div p {
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 5px;
}

.inquiry-offcanvas .card-set .align-delete {
  text-align: center;
}

.inquiry-offcanvas .card-set .align-delete .delete-icon {
  margin-top: 70px;
}

.head-logo .offcanvas-btn {
  outline: 0;
  box-shadow: 0px 0px 0px 0px;
}

.cart-offcanvas .title {
  font-size: 25px;
  font-weight: 700;
}

.cart-offcanvas .sub-title {
  font-size: 18px;
}

.cart-offcanvas .offcanvas-header {
  border-bottom: 1px solid #a8a8a8;
}

.cart-offcanvas .bd-bottom {
  border-bottom: 1px solid #a8a8a8;
  padding: 10px;
}

.cart-offcanvas .inquiry-btn,
.cart-offcanvas .inquiry-btn:hover {
  background-color: #000;
  color: white;
  font-size: 14px;
  width: 100%;
  border-radius: 5px;
}

.cart-offcanvas .overflow-canvas {
  overflow-y: scroll;
  /* height: 373px; */
  height: 45%;
  overflow-x: hidden;
}

.cart-offcanvas .card-set {
  height: 157px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.cart-offcanvas .card {
  border: 0px;
}

.cart-offcanvas .card-set .fa-heart {
  display: block;
}

.cart-offcanvas .card-set .img-div {
  height: 110px;
  width: 100%;
  object-fit: cover;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cart-offcanvas .card-set .cart-price {
  font-size: 15px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.cart-offcanvas .card-set .fafa-cancel {
  color: white;
  background-color: #000;
  padding: 6px 9px;
  border-radius: 25px;
}

.cart-offcanvas .card-set .cancel-product {
  text-align: right;
}

.cart-offcanvas .cancel-product .price-p2 {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 25px;
}

.cart-offcanvas .card-set .img-div:hover .inquiry-img {
  overflow: hidden;
  box-sizing: border-box;
  transition: 0.4s;
  transform: scale(1.1);
}

.cart-offcanvas .card-set .img-div .inquiry-img {
  transition: 0.4s;
  width: 60%;
}

.cart-offcanvas .checkbox-div {
  width: 100%;
  height: 157px;
  max-height: 100%;
  object-fit: cover;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-offcanvas .checkbox-div .checkbox:checked {
  background-color: #767676;
  border-color: #767676;
}

.cart-offcanvas .row-set {
  width: 100%;
  height: 157px;
  max-height: 100%;
  object-fit: cover;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart-offcanvas .card-set .select-product-div .pro-name {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 0px;
}

.cart-offcanvas .card-set .add-cart-box {
  border: 1px solid #707070;
  border-radius: 5px;
  width: fit-content;
  margin-top: 10px;
}

.cart-offcanvas .card-set .add-cart-btn {
  font-size: 14px;
  background-color: #000;
  color: white;
  margin-top: 7px;
  padding: 7px 25px;
}

.cart-offcanvas .card-set .add-cart-box .minus {
  background-color: #f6f6f6;
  border-right: 1px solid #707070;
  padding: 3px 10px;
  font-size: 17px;
  border-radius: 5px;
}

.cart-offcanvas .card-set .add-cart-box .quantity {
  background-color: #fff;
  padding: 3px 15px;
  font-size: 17px;
}

.cart-offcanvas .card-set .select-product-div2 {
  padding-top: 0%;
}

.cart-offcanvas .card-set .select-product-div2 .add-btn {
  font-size: 20px;
  background-color: black;
  color: white;
  font-weight: 700;
  border-radius: 50px;
  padding: 0px 11px;
}

.cart-offcanvas .card-set .select-product-div2 .list {
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 5px;
  padding-left: 0px;
}

.cart-offcanvas .card-set .select-product-div .price {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.cart-offcanvas .card-set .select-product-div2 .price {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.cart-offcanvas .card-set .align-delete {
  text-align: center;
}

.cart-offcanvas .card-set .align-delete .delete-icon {
  margin-top: 58px;
}

.cart-offcanvas .card-set .flex-item {
  display: flex;
}

.price-row-1 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}

.price-row-2 {
  font-size: 20px;
  margin-bottom: 0px;
}

.all-header-section .main-nav .hrderropp .dropdown-toggle {
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 15px 10px 0px;
}
.all-header-section .main-nav .hrderropp .dropdown-item p {
  font-size: 15px;
  text-wrap: wrap;
  margin-bottom: 0px;
}
.all-header-section .main-nav .hrderropp .dropdown-item:hover {
  background-color: #359271;
  color: #fff;
}
.all-header-section .main-nav .hrderropp .dropdown-item.active,
.all-header-section .main-nav .hrderropp .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #359271;
  /* color: #fff !important; */
}

.listtexxt.list-group-item {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.cartttdataaaover {
  height: 460px;
  overflow-x: hidden;
  overflow-y: scroll;
}


.whistlist-ofcanvas {
  visibility: visible;
  height: 100% !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.float-label-top {
  display: none !important;
}

.whistlist-ofcanvas .offcanvas-body {
  overflow: hidden;
  padding: 0px;
}
.whistlist-ofcanvas .main-div2 {
  /* height: 500px; */
  overflow-y: scroll !important;
}
.searchmainnn {
  position: relative !important;
  margin: 0px auto;
}

.searchmainnn .list-group {
  position: absolute;
  width: 70%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  left: 15%;
}
.tbclass {
  padding: 0px 15px;
  position: sticky;
  bottom: 0;
}
.main-nav .navbar .dropdown-menu .dropdown-item a {
  color: #000 !important;
}
.main-nav .navbar .dropdown-menu .dropdown-item:hover a {
  color: #fff !important;
}
@media (max-width: 320px) {
  .head-logo .background {
    padding: 10px 0px;
  }
  .all-header-section .main-nav .hrderropp .dropdown-toggle {
    font-size: 15px;
  }
  .head-logo .background .logo-img {
    width: 100%;
    height: auto;
  }

  .head-logo .search .search-btn {
    font-size: 12px;
  }

  .head-logo .background .d-flex .badges-main2 {
    border-radius: 50px;
    width: 40px;
  height: 40px;
    margin-left: 5px;
    outline-offset: -4px;
    margin-right: 0px;
  }

  .head-logo .search .input-group {
    margin: 0px;
    padding: 2px;
    width: 100%;
  }

  .searchmainnn .list-group {
    /* position: absolute; */
    width: 100%;
    /* z-index: 999;
    display: flex;
    justify-content: center; */
    left: 0%;
  }

  .head-logo .search .form-control {
    font-size: 15px;
  }

  .head-logo .search .form-control {
    padding: 0px;
    padding-left: 15px;
  }

  .head-logo .search .search-btn {
    font-size: 15px;
    padding-right: 10px;
  }

  .head-logo .background .d-flex .badges-main {
    width: 40px;
    height: 40px;
    margin-top: 5px;
  }

  .head-logo .background .d-flex .badges-main2 {
    width: 40px;
    height: 40px;
    margin-top: 5px;
  }

  .inquiry-offcanvas .title {
    font-size: 18px;
  }

  .inquiry-offcanvas .sub-title {
    font-size: 14px;
  }

  .inquiry-offcanvas .card-set .select-product-div .pro-name {
    font-size: 15px;
    font-weight: 600;
  }

  .inquiry-offcanvas .card-set {
    height: 120px;
  }

  .inquiry-offcanvas .card-set .img-div {
    height: 119px;
  }

  .inquiry-offcanvas .card-set .select-product-div .add-btn {
    font-size: 16px;
    padding: 0px 8px;
  }

  .inquiry-offcanvas .card-set .align-delete .delete-icon {
    margin-top: 55px;
  }

  .inquiry-offcanvas .offcanvas {
    max-width: 85%;
  }

  .cart-offcanvas .offcanvas {
    max-width: 85%;
  }

  .cart-offcanvas .title {
    font-size: 18px;
  }

  .cart-offcanvas .card-set .select-product-div .pro-name {
    font-size: 15px;
    font-weight: 600;
    padding-top: 20%;
  }

  .cart-offcanvas .card-set .select-product-div2 .price {
    font-size: 15px;
  }

  .cart-offcanvas .card-set .select-product-div2 .add-btn {
    font-size: 16px;
    padding: 0px 8px;
  }

  .cart-offcanvas .card-set .img-div {
    height: 120px;
  }

  .cart-offcanvas .card-set {
    height: 121px;
  }

  .cart-offcanvas .price-row p {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .cart-offcanvas .card-set .add-cart-btn {
    font-size: 12px;
    padding: 3px 3px;
  }
  .whistlist-ofcanvas .main-div p.price {
    font-size: 15px !important;
    margin-top: 0px !important;
  }
  .listtexxt.list-group-item {
    font-size: 12px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .whistlist-ofcanvas .main-div p.price {
    font-size: 16px !important;
    margin-top: 0px !important;
  }
  .head-logo .background {
    padding: 10px 0px;
  }
  .all-header-section .main-nav .hrderropp .dropdown-toggle {
    font-size: 15px;
  }
  .head-logo .background .logo-img {
    /* width: 100%;
        height: auto; */
  }

  .head-logo .background .d-flex .badges-main2 {
    border-radius: 50px;
    width: 40px;
  height: 40px;
    /* border: 1px solid #F7FF00; */
    margin-left: 5px;
    outline-offset: -4px;
    margin-right: 0px;
  }

  .head-logo .search .input-group {
    margin: 0px 0px 0px 0px;
    width: 100%;
  }
  .searchmainnn .list-group {
    /* position: absolute; */
    width: 100%;
    /* z-index: 999;
    display: flex;
    justify-content: center; */
    left: 0%;
  }

  .head-logo .search .form-control {
    font-size: 15px;
  }

  .head-logo .search .form-control {
    padding: 0px;
    padding-left: 15px;
  }

  .head-logo .search .search-btn {
    font-size: 15px;
    padding-right: 10px;
    padding: 3px 12px;
  }

  .head-logo .background .d-flex .badges-main {
    width: 40px;
    height: 40px;
    margin-top: 5px;
  }

  .head-logo .background .d-flex .badges-main2 {
    width: 40px;
    height: 40px;
    margin-top: 5px;
  }

  .inquiry-offcanvas .title {
    font-size: 18px;
  }

  .inquiry-offcanvas .sub-title {
    font-size: 14px;
  }

  .inquiry-offcanvas .card-set .select-product-div .pro-name {
    font-size: 15px;
    font-weight: 600;
  }

  .inquiry-offcanvas .card-set {
    height: 120px;
  }

  .inquiry-offcanvas .card-set .img-div {
    height: 119px;
  }

  .inquiry-offcanvas .card-set .select-product-div .add-btn {
    font-size: 16px;
    padding: 0px 8px;
  }

  .inquiry-offcanvas .card-set .align-delete .delete-icon {
    margin-top: 55px;
  }

  .inquiry-offcanvas .offcanvas {
    max-width: 85%;
  }

  .cart-offcanvas .offcanvas {
    max-width: 85%;
  }

  .cart-offcanvas .title {
    font-size: 18px;
  }

  .cart-offcanvas .card-set .select-product-div .pro-name {
    font-size: 15px;
    font-weight: 600;
    padding-top: 20%;
  }

  .cart-offcanvas .card-set .select-product-div2 .price {
    font-size: 15px;
  }

  .cart-offcanvas .card-set .select-product-div2 .add-btn {
    font-size: 16px;
    padding: 0px 8px;
  }

  .cart-offcanvas .card-set .img-div {
    height: 120px;
  }

  .cart-offcanvas .card-set {
    height: 121px;
  }

  .cart-offcanvas .price-row p {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .cart-offcanvas .card-set .add-cart-btn {
    font-size: 12px;
    padding: 3px 3px;
  }
  .listtexxt.list-group-item {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .head-logo .background {
    padding: 10px 0px;
  }

  .head-logo .background .logo-img {
    /* width: 70%;
        height: auto; */
    margin-left: 34%;
  }

  .head-logo .background .d-flex .badges-main2 {
    border-radius: 50px;
    width: 40px;
  height: 40px;
    margin-left: 5px;
    outline-offset: -4px;
    margin-right: 0px;
  }

  .head-logo .search .input-group {
    margin: 0px 0px 0px 0px;
    width: 100%;
  }
  .searchmainnn .list-group {
    /* position: absolute; */
    width: 100%;
    /* z-index: 999;
    display: flex;
    justify-content: center; */
    left: 0%;
  }

  .head-logo .search .form-control {
    font-size: 15px;
  }

  .head-logo .search .form-control {
    padding: 0px;
    padding-left: 15px;
  }

  .head-logo .search .search-btn {
    font-size: 15px;
  }

  .head-logo .background .d-flex .badges-main {
    /* width: 50px;
    height: 50px; */
    margin-top: 0px;
  }

  .head-logo .background .d-flex .badges-main2 {
    /* width: 50px;
    height: 50px; */
    margin-top: 0px;
  }
  .listtexxt.list-group-item {
    font-size: 14px;
  }
}

@media (min-height: 280px) and (max-height: 600px) {
  .cart-offcanvas .overflow-canvas {
    height: 45%;
  }

  .cart-offcanvas .offcanvas-header {
    padding: 0px;
  }

  .inquiry-offcanvas .offcanvas-header {
    padding: 0px;
  }

  .inquiry-offcanvas .overflow-canvas {
    height: 45%;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
.searchmainnn {
  margin: 0px 0px;
}

.head-logo .background .search-bar-box {
  justify-content: end;
}
}

/* end logo section style  */

/* start navbar section style  */
.all-header-section {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1020;
}

.main-nav {
  /* position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 1020; */
}

.main-nav .navbar-background {
  background-color: #359271;
  padding: 0;
}

.main-nav .navbar-background .container .navbar-menu-bg .nav-menu {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.main-nav .navbar-background .container .navbar-menu-bg .nav-menu.active {
  border-bottom: 5px solid #fff;
  padding-bottom: 7px;
}

.main-nav .nav-item {
  padding: 5px 15px 5px 15px;
}

.main-nav .navbar-background .container .navbar-menu-bg .activenav a {
  color: #fff;
}

.main-nav li {
  cursor: pointer;
}

/* .main-nav .navbar-background .container .navbar-menu-bg .dropdown-toggle::after{
  background-image: url("../../icon/dowm-arrow.png") !important;
} */
.main-nav .navbar-nav .dropdown-menu {
  border: 1px solid #359271;
  width: 250px;
}

.main-nav .next-fafa {
  font-size: 11px;
  position: absolute;
  right: 10px;
}

.main-nav .fafa-next-pos {
  position: relative;
}

.cont-pos {
  position: relative;
  overflow: visible;
  text-align: center;
}

.main-nav .main-nav-more {
  display: inline-flex;
  padding: 0;
  list-style: none;
}

.main-nav .main-nav-more li a {
  padding: 12px 0px 5px 0px;
  white-space: nowrap;
  text-decoration: none;
}

/* .main-nav .dropdown:hover .drop-menu {
  display: block;
  position: absolute;
  top: 100%;
  z-index: -999;
} */

.main-nav .main-nav-more li a.active,
.main-nav .main-nav-more li a:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

/* .main-nav .dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
} */

/* ofcanvas css start */
.whistlist-ofcanvas .offcanvas-header {
  justify-content: space-evenly;
  flex-direction: row-reverse;
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 1px 1px 5px -1px #ccc;
}

.whistlist-ofcanvas .offcanvas-header .btn-close {
  margin-left: 0px;
  left: 12px;
  position: absolute;
}

.whistlist-ofcanvas .offcanvas-title {
  font-size: 25px;
  font-weight: bold;
}

.whistlist-ofcanvas .offcanvas-header p {
  font-size: 18px;
  color: #323232;
  font-weight: 400;
  margin-bottom: 0px;
  position: absolute;
  right: 12px;
}

.whistlist-ofcanvas .pro-div .img-div .cart-img {
  width: 100px;
  height: 100px;
  padding: 10px;
  object-fit: contain;
}

.whistlist-ofcanvas .pro-div .img-div {
  display: flex;
  border: 1px solid #b7b7b7;
  border-radius: 5px;

  padding: auto;
  justify-content: center;
  align-items: center;
}

.whistlist-ofcanvas .add-to-card {
  background: #359271 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: 0.41px;
  color: #ffffff;
  opacity: 1;
  width: 100px;
  height: 38px;
  border: none;
  margin: 10px 0px;
}

.whistlist-ofcanvas .pro-div .product-info p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
}

.whistlist-ofcanvas .pro-div .heart-div {
  color: red;
  font-size: 15px;
}

.whistlist-ofcanvas .pro-div {
  border-left: none;
  border-right: none;
  border-top: 1px solid #c3c5c9;
  border-bottom: 1px solid #c3c5c9;
  padding: 10px 40px 10px 10px;
}

/* .whistlist-ofcanvas .main-div {
  height: 100vh;
} */

.whistlist-ofcanvas .view-btn {
  width: 100%;
}

.whistlist-ofcanvas .main-div th {
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
}

.whistlist-ofcanvas .main-div td {
  font-size: 20px;
  font-weight: 400;
}

.whistlist-ofcanvas .main-div .icon-btn {
  padding: 0px;
  border: none;
  background-color: transparent;
}

.whistlist-ofcanvas .main-div p.price {
  font-size: 18px;
  font-weight: 400;
  /* margin-top: 34px; */
}

.whistlist-ofcanvas .main-div p.prices {
  font-size: 15px;
  font-weight: 400;
}

.main-nav .mobile-view-btn {
  display: none;
}

.main-nav .mobile-view-btn .log-in-btn {
  color: white;
  font-size: 15px;
  margin-bottom: 0px;
  cursor: pointer;
  font-weight: 700;
}

@media (min-width: 0px) and (max-width: 400px) {
  .whistlist-ofcanvas .pro-div .img-div .cart-img {
    width: 74px;
    height: 73px;
    padding: 10px;
  }

  .whistlist-ofcanvas .add-to-card {
    font-size: 12px;
    width: 100px;
    height: 32px;
    margin: 7px 0px;
  }

  .whistlist-ofcanvas .pro-div .product-info p {
    font-size: 14px;
  }

  .whistlist-ofcanvas .view-btn {
    width: 100%;
  }

  .whistlist-ofcanvas .main-div th {
    font-size: 15px;
  }

  .whistlist-ofcanvas .main-div td {
    font-size: 15px;
  }

  .whistlist-ofcanvas .pro-div {
    padding: 15px 10px 10px 10px;
  }

  .main-nav .mobile-view-btn {
    display: block;
  }
  .head-logo .background .d-flex .icon-img {
    width: 20px;
    height: 20px;
  }
  .main-nav .nav-item {
    padding: 5px 15px 7px 0px;
  }
  .main-nav .navbar-background .container .navbar-menu-bg .nav-menu.active {
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
  }
}

@media (min-width: 401px) and (max-width: 768px) {
  .whistlist-ofcanvas .add-to-card {
    font-size: 12px;
    width: 100px;
    height: 32px;
  }

  .whistlist-ofcanvas .pro-div .product-info p {
    font-size: 14px;
  }

  .whistlist-ofcanvas .view-btn {
    width: 100%;
  }

  .whistlist-ofcanvas .main-div th {
    font-size: 16px;
  }

  .whistlist-ofcanvas .main-div td {
    font-size: 16px;
  }

  .main-nav .mobile-view-btn {
    display: block;
  }
  .head-logo .background .d-flex .icon-img {
    width: 20px;
    height: 20px;
  }
  .main-nav .nav-item {
    padding: 5px 15px 7px 0px;
  }
  .main-nav .navbar-background .container .navbar-menu-bg .nav-menu.active {
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .main-nav .navbar-background {
    box-shadow: 0px 0px 10px 0px #ccc;
    z-index: 999;
  }

  .main-nav .navbar-background .container .navbar-menu-bg .activenav {
    border-bottom: 3px solid #fff;
    width: fit-content;
  }

  .main-nav .productmob {
    display: block;
  }

  .cont-pos {
    text-align: left;
    position: relative;
  }

  .nav-tog-space {
    z-index: 999;
    position: absolute;
    left: 0px;
  }

  .main-nav .toggle-btn {
    background: white;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    position: relative;
    bottom: 50px;
  }

  .main-nav .navbar-toggler:focus {
    box-shadow: 0 0 0 #fff;
  }

  .main-nav .navbar-background .container .navbar-menu-bg .nav-menu {
    color: white;
    font-size: 15px;
    font-weight: 600;
  }

  .main-nav .navbar-background .navbar-menu-bg {
    width: 100%;
  }

  .main-nav .product-acc-drop:not(.collapsed) {
    color: #000;
    background-color: #fff;
    box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%);
  }

  .main-nav .main-nav-more li a {
    padding: 10px 30px;
    font-size: 12px;
    font-weight: 700;
  }
  .main-nav .mobile-view-btn {
    display: block;
  }
  .main-nav .nav-item {
    padding: 5px 15px 7px 0px;
  }
  .main-nav .navbar-background .container .navbar-menu-bg .nav-menu.active {
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  /* .all-header-section {
        position: unset;
    } */

  .main-nav .toggle-btn {
    right: 25px;
  }

  .main-nav .toggle-btn {
    bottom: 52px;
  }
  /* .head-logo .background .d-flex .icon-img {
    width: 29px;
    height: 29px;
  } */
}

@media (min-width: 992px) and (max-width: 1024px) {
  .main-nav .navbar-background .container .navbar-menu-bg .nav-menu {
    color: white;
    font-size: 13px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .main-nav .navbar-background .container .navbar-menu-bg .nav-menu {
    font-size: 13px;
  }

  .main-nav .auto-nav-more-list {
    left: -160%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .main-nav .navbar-background .container .navbar-menu-bg .nav-menu {
    color: white;
    font-size: 15px;
  }
}

/* end navbar section style  */

/* start mobileNav section style  */

.mobileNav {
  display: none;
  border-top: 2px solid #000;
}

.mobileNav .menuBtn {
  background-color: #6e3ce1;
  width: auto;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  bottom: 24px;
  left: 13px;
}

.mobileNav .offcanvas.offcanvas-start {
  width: 350px;
}

.mobileNav .offcanvas.offcanvas-start .offcanvas-title {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.mobileNav .offcanvas.offcanvas-start .offcanvas-body {
  background-color: white;
}

.mobileNav .offcanvas.offcanvas-start .offcanvas-body .nav .nav-link {
  /* color: #6e3ce1; */
  color: black;
  font-family: "Lato", "sans-serif";
  border-radius: 17px;
  font-weight: 600;
  /* margin-bottom: 4%;    */
  padding-bottom: 15px;
  padding-right: 10px;
  font-size: 14px;
}

.mobileNav .accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: white;
  box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%);
}

.mobileNav .drop-memu {
  color: #6e3ce1;
  font-weight: 600;
  font-size: 14px;
}

.mobileNav .acc-body-bg {
  background: #6e3ce114;
}

.mobileNav .offcanvas.offcanvas-start .offcanvas-body .nav {
  margin-bottom: 5%;
  border-bottom: 2px solid #f2f2f2;
}

.mobileNav .offcanvas.offcanvas-start .offcanvas-body .nav .nav-link.active {
  padding: 8px 15px;
  background: #6e3ce1;
  color: #fff;
  font-family: "Lato", "sans-serif";
  border-radius: 17px;
  font-weight: 500;
}

.mobileNav .mobileBtn .btn {
  padding: 8px 22px;
  border-radius: 24px;
  font-weight: 500;
  font-size: 14px;
}

.mobileNav .offcanvas-start .logo-img {
  width: 50%;
}

.mobileNav .offcanvas-start .head-bg {
  background-color: #6e3ce1;
}

.mobileNav .offcanvas-start .qr-image {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.mobileNav .offcanvas-start .logout {
  color: red;
  background: white;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
}

.mobileNav .offcanvas-start .btn-close {
  box-sizing: content-box;
  width: 3em;
  height: 2em;
  padding: 0.25em 0.25em;
  color: #fff;
  /* background-image: url(../../icon/close.png); */
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  background-color: white;
}

.mobileNav .form-select {
  padding: 5px 33px 5px 5px;
  font-size: 12px;
  margin-top: 5px;
  box-shadow: 0px 0px 5px 0px #707070;
}

.mobileNav .form-select:focus {
  box-shadow: 0px 0px 5px 0px #707070;
}

.mobileNav .currency-btn {
  font-size: 12px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 0px #707070;
  padding: 5px 8px;
}

.mobileNav .currency-btn:focus {
  box-shadow: 0px 0px 5px 0px #707070;
}

.mobileNav .navbar-nav .dropdown-menu {
  position: static;
  height: 150px;
  overflow-y: scroll;
}

.mobileNav .offcanvas-body .navbar-nav .d-flex .dropdown-item {
  font-size: 11px;
}

.mobileNav .flag-icon {
  width: 20px;
}
