/* start offer partner style  */
.offer .background {
    background-image: linear-gradient(180deg, rgb(0 0 0 / 45%) 0%, #00000066 100%), url("../../../../public/assets/Image/Homepage/offer/offer-bg.jpg");
    height: 347px;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    display: flex;
    align-items: center;
}

.offer .border-div {
    border: 2px solid #fff;
}

.offer .offer-text-div {
    background-color: #fff;
    opacity: 0.7;
    margin: 30px;
    text-align: center;
    padding: 4%;
}

.offer .offer-text-div h1 {
    font-size: 45px;
    color: #000;
}

.offer .offer-text-div h4 {
    font-size: 29px;
    color: #000;
}



@media (max-width:575px) {
    .offer .offer-text-div h1 {
        font-size: 20px;
    }

    .offer .offer-text-div h4 {
        font-size: 15px;
    }

    .offer .offer-text-div {
        margin: 15px;
    }

    .offer .background {
        height: 250px;
    }
}

@media (min-width:576px) and (max-width:768px) {
    

    .offer .offer-text-div h1 {
        font-size: 30px;
    }

    .offer .offer-text-div h4 {
        font-size: 20px;
    }

    .offer .background {
        height: 300px;
    }
}

@media (min-width:769px) and (max-width:992px) {
    

    .offer .offer-text-div h1 {
        font-size: 35px;
    }

    .offer .offer-text-div h4 {
        font-size: 25px;
    }

    .offer .background {
        height: 280px;
    }
}

@media (min-width:993px) and (max-width:1024px) {
    

    .offer .offer-text-div h1 {
        font-size: 35px;
    }

    .offer .offer-text-div h4 {
        font-size: 25px;
    }

    .offer .background {
        height: 280px;
    }
}

/* end offer partner style  */