/* progress bar css start*/
.checkout-process-step-form {
  margin: 0px 0px;
}

.checkout-process-step-form .btnnnnnn {
  background: transparent;
  border: none;
  font-size: 20px;
}

.checkout-process-step-form .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">");
}
.checkout-process-step-form a {
  color: black;
}
.checkout-process-step-form .progress-bar-container .step-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.existing-adress .reddlttt{
  justify-content: space-between;
}

.checkout-process-step-form .progress-bar-container .step-container {
  position: relative;
  width: 100%;
  margin-right: 30px;
}

.checkout-process-step-form .progress-bar-container .step-text {
  font-size: 14px;
  color: #b2b2b2;
  font-weight: 400;
}

.checkout-process-step-form .progress-bar-container .step-text.active,
.checkout-process-step-form .progress-bar-container .step-text.completed {
  font-weight: bold;
  color: #000000;
}

.checkout-process-step-form .progress-bar-container .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  color: #000;
  font-size: 20px;
  border: 2px solid #b2b2b2;
  width: 100%;
}

.checkout-process-step-form .progress-bar-container .circle.completed {
  border: 2px solid #359271;
}

.checkout-process-step-form .step-container.active .circle {
  border: 2px solid #359271;
}

.checkout-process-step-form .progress-bar-container {
  margin: 30px 0px;
}

/* progress bar css end */

.checkout-process-step-form .checkout-inner-steps {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
  opacity: 1;
  padding: 30px;
  margin: 20px 0px;
}

.checkout-process-step-form .checkout-inner-steps .main-btn-div {
  text-align: end;
}

.checkout-process-step-form .checkout-inner-steps .main-btn-div .save-btn {
  background: #212121 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  border: none;
  padding: 8px 10px;
  width: 137px;
  margin: 10px;
}

.checkout-process-step-form .checkout-inner-steps .main-btn-div .back-btn {
  background: #b7b7b7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  border: none;
  padding: 8px 10px;
  width: 137px;
  margin: 10px;
}

.checkout-process-step-form
  .checkout-inner-steps
  .home-word-div
  .form-check-label {
  border: 1px solid #707070;
  padding: 2px 15px;
  border-radius: 5px;
  color: #323232;
  font-size: 13px;
  font-weight: 500;
}

.checkout-process-step-form .checkout-inner-steps .home-word-div {
  margin-left: 27px;
}



.checkout-process-step-form .checkout-inner-steps .bold-text .form-check-label {
  color: #323232;
  font-size: 14px;
  font-weight: bold;
  margin-top: 7px;
}

.checkout-process-step-form .checkout-inner-steps .home-word-div .form-label {
  color: #323232;
  font-size: 14px;
  font-weight: bold;
}

.checkout-process-step-form .checkout-inner-steps .bold-text .form-check-input {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.checkout-process-step-form .checkout-inner-steps .new-adress {
  border-left: 1px solid grey;
  padding-left: 30px;
}

.checkout-process-step-form .checkout-inner-steps .form-check-input:checked {
  background-image: radial-gradient(circle at 50% 50%, #000 40%, #fff 0);
  border: 1px solid #000000;
}

.checkout-process-step-form .checkout-inner-steps .form-check-input:focus {
  border-color: #000;
  box-shadow: none;
}

.checkout-process-step-form .checkout-inner-steps .form-check-input {
  width: 14px;
  height: 14px;
}

.checkout-process-step-form .checkout-inner-steps .new-adress .form-label {
  font-size: 14px;
  font-weight: bold;
  width: inherit;
}

.checkout-process-step-form .checkout-inner-steps .form-grp {
  display: flex;
  width: 100%;
}

.checkout-process-step-form .checkout-inner-steps .form-control {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  font-size: 14px;
  color: #000;
}

.checkout-process-step-form .checkout-inner-steps .form-select {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  font-size: 14px;
}

.checkout-process-step-form .checkout-inner-steps .form-control:focus {
  box-shadow: none;
}

/* second step css */
.checkout-process-step-form .checkout-inner-steps .react-datepicker-wrapper {
  width: 100%;
}

.checkout-process-step-form
  .checkout-inner-steps
  .react-datepicker__view-calendar-icon
  input {
  width: 100%;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: 1px solid grey;
}

.checkout-process-step-form .delivary-step .form-label {
  font-size: 14px;
  font-weight: bold;
}

.checkout-process-step-form .delivary-step .star {
  color: red;
}

.checkout-process-step-form .delivary-step .react-time-picker {
  width: 100%;
}
.checkout-process-step-form .delivary-step .datepickerdiv {
  position: relative;
}
.checkout-process-step-form .delivary-step .calender-div {
  position: absolute;
  right: 22px;
  top: 10px;
}
.checkout-process-step-form .delivary-step .react-time-picker__wrapper {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: 1px solid grey;
  padding: 4px 15px;
}

.checkout-process-step-form .delivary-step .banner {
  width: 100%;
  height: 261px;
  object-fit: cover;
  margin-bottom: 10px;
}

/* third step css */

.checkout-process-step-form .payment-step h1 {
  font-size: 24px;
  font-weight: bold;
}
.checkout-process-step-form .payment-step .bold-text .form-check-label {
  font-weight: 500;
}

.checkout-process-step-form .payment-step .payment-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #00000012;
  border-radius: 7px;
  opacity: 1;
  padding: 10px;
  height: 180px;
  margin: 10px 0px;
}

.checkout-process-step-form .payment-step .payment-card p {
  font-size: 18px;
  font-weight: bold;
}

.checkout-process-step-form .payment-step .pay-imgs {
  width: 70px;
  height: 30px;
  border-radius: 5px;
  object-fit: contain;
}

.checkout-process-step-form .payment-step .img-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #b2b2b2;
  border-radius: 5px;
  opacity: 1;
  padding: 5px 10px;
  margin-left: 10px;
}

.checkout-process-step-form .payment-step .radio-btns {
  display: flex;
  align-items: center;
}

/* forth step css */
.checkout-process-step-form .conform-table th {
  font-size: 16px;
  font-weight: bold;
  text-wrap: nowrap;
  text-align: center;
}

.checkout-process-step-form .conform-table td {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.checkout-process-step-form .checkout-inner-steps .new-adress .main-heading-text .heading-text {
  font-size: 20px;
}

.checkout-process-step-form .conform-table .img-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #b2b2b2;
  border-radius: 5px;
  opacity: 1;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 60px;
  width: 60px;
}

.checkout-process-step-form .conform-table .main-img-div .img-div .product-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.checkout-process-step-form .conform-table .main-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-process-step-form .conform-table .second-table th {
  width: 70%;
  padding: 7px;
}

.checkout-process-step-form .conform-table .second-table td {
  font-size: 15px;
  font-weight: 400;
}



@media (min-width: 0px) and (max-width: 576px) {
  .checkout-process-step-form .progress-bar-container .step-text {
    font-size: 13px;
  }
  .checkout-process-step-form .checkout-inner-steps {
    padding: 25px 10px;
  }

  .checkout-process-step-form .checkout-inner-steps .new-adress {
    border-left: none;
    padding-left: 0px;
  }

  .checkout-process-step-form .checkout-inner-steps .form-grp {
    display: block;
  }

  .checkout-process-step-form .progress-bar-container .step-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 630px;
  }

  .checkout-process-step-form .progress-bar-container {
    margin: 30px 0px;
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .checkout-process-step-form
    .checkout-inner-steps
    .bold-text
    .form-check-label {
    font-size: 13px;
  }

  .checkout-process-step-form
    .checkout-inner-steps
    .home-word-div
    .form-check-label {
    font-size: 12px;
  }

  .checkout-process-step-form .checkout-inner-steps .new-adress .form-label {
    font-size: 13px;
  }

  .checkout-process-step-form .checkout-inner-steps .home-word-div {
    margin-left: 10px;
  }

  .checkout-process-step-form .checkout-inner-steps .home-word-div .form-label {
    font-size: 12px;
  }

  .checkout-process-step-form .payment-step h1 {
    font-size: 18px;
  }

  .checkout-process-step-form .payment-step .payment-card p {
    font-size: 16px;
  }

  .checkout-process-step-form .conform-table .first-table {
    overflow-x: scroll;
    width: 100%;
  }

  .checkout-process-step-form .conform-table th {
    font-size: 14px;
  }

  .checkout-process-step-form .conform-table td {
    font-size: 12px;
  }

  .checkout-process-step-form .conform-table .second-table td {
    font-size: 13px;
  }

  .checkout-process-step-form .conform-table .second-table .form-control {
    width: 100%;
  }

  .checkout-process-step-form .checkout-inner-steps .main-btn-div .save-btn {
    font-size: 11px;
    width: 100px;
  }

  .checkout-process-step-form .checkout-inner-steps .main-btn-div .back-btn {
    font-size: 12px;
    width: 100px;
  }
  .checkout-process-step-form .delivary-step .calender-div {
    top: 41px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .checkout-process-step-form .progress-bar-container .step-text {
    font-size: 13px;
  }
  .checkout-process-step-form .checkout-inner-steps {
    padding: 25px 20px;
  }

  .checkout-process-step-form .checkout-inner-steps .new-adress {
    border-left: none;
    padding-left: 0px;
  }

  .checkout-process-step-form .progress-bar-container .step-row {
    width: 610px;
  }

  .checkout-process-step-form .progress-bar-container {
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .checkout-process-step-form
    .checkout-inner-steps
    .bold-text
    .form-check-label {
    font-size: 13px;
  }

  .checkout-process-step-form
    .checkout-inner-steps
    .home-word-div
    .form-check-label {
    font-size: 12px;
  }

  .checkout-process-step-form .checkout-inner-steps .new-adress .form-label {
    font-size: 13px;
  }

  .checkout-process-step-form .checkout-inner-steps .home-word-div {
    margin-left: 10px;
  }

  .checkout-process-step-form .payment-step h1 {
    font-size: 19px;
  }

  .checkout-process-step-form .checkout-inner-steps .home-word-div .form-label {
    font-size: 12px;
  }

  .checkout-process-step-form .payment-step .payment-card p {
    font-size: 16px;
  }

  .checkout-process-step-form .conform-table .first-table {
    overflow-x: scroll;
    width: 100%;
  }

  .checkout-process-step-form .conform-table th {
    font-size: 14px;
  }

  .checkout-process-step-form .conform-table td {
    font-size: 12px;
  }

  .checkout-process-step-form .conform-table .second-table td {
    font-size: 13px;
  }

  .checkout-process-step-form .conform-table .second-table .form-control {
    width: 100%;
  }

  .checkout-process-step-form .checkout-inner-steps .main-btn-div .save-btn {
    font-size: 11px;
    width: 100px;
  }

  .checkout-process-step-form .checkout-inner-steps .main-btn-div .back-btn {
    font-size: 12px;
    width: 100px;
  }
  .checkout-process-step-form .delivary-step .calender-div {
    top: 41px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .checkout-process-step-form .checkout-inner-steps .new-adress {
    border-left: none;
    padding-left: 0px;
  }

  .checkout-process-step-form .payment-step h1 {
    font-size: 20px;
  }

  .checkout-process-step-form .conform-table .first-table {
    overflow-x: scroll;
    width: 100%;
  }
}
