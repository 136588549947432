.Purchased_Items_Modal .stepper-div .ranges-holder {
  display: flex;
  justify-content: space-between;
}

.Purchased_Items_Modal .range-text-div {
  margin: 0px;
}

.Purchased_Items_Modal .product-section-div {
  border: none !important;
  margin-bottom: 10%;
  margin-top: 0%;
}

.Purchased_Items_Modal .modal-content {
  border: none;
  box-shadow: none;
}

.Purchased_Items_Modal .RSPBprogressBar {
  width: 90%;
  height: 5px;
}

.Purchased_Items_Modal .RSPBprogressBar .indexedStep.accomplished {
  background-color: #df4223;
  width: 15px;
  height: 15px;
}

.Purchased_Items_Modal .RSPBprogressBar .indexedStep.null {
  width: 15px;
  height: 15px;
}

.Purchased_Items_Modal .RSPBprogressBar .indexedStep1.accomplished {
  background-color: #086A0F;
  width: 15px;
  height: 15px;
  border-radius: 50px;
}

.Purchased_Items_Modal .RSPBprogressBar .indexedStep1.null {
  width: 15px;
  height: 15px;
}

.Purchased_Items_Modal .indexedStep1 {
  position: relative;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Purchased_Items_Modal .modal-body {
  border: none;
  height: 100%;
  /* overflow: hidden; */
  overflow-y: scroll;
  margin-bottom: 5%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 17px #00000012;
  border: 1px solid #f6f6f6;
  border-radius: 10px;
}

.Purchased_Items_Modal .modal-content {
  background: none;
  border: none;
}

.Purchased_Items_Modal .border-text-right {
  border-right: 1px solid #707070;
}

.Purchased_Items_Modal .return-button {
  margin-top: 15%;
}

.Purchased_Items_Modal .return-button .return-btn {
  background: #B7B7B7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #FFF;
  height: 38px;
  padding: 0px 55px;
  font-size: 18px;
  border: none;
}

.Purchased_Items_Modal .return-button .return-btn:hover {
  background: #B7B7B7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #FFF;
  height: 38px;
  padding: 0px 55px;
  font-size: 18px;
  border: none;
}

@media (min-width: 280px) and (max-width: 320px) {
  .Purchased_Items_Modal .RSPBprogressBar {
    width: 100%;
  }

  .Purchased_Items_Modal .RSPBprogressBar .placed {
    font-size: 10px;
  }

  .Purchased_Items_Modal .product-section-div .product-div .product .img-set {
    height: 50px;
  }

  .Purchased_Items_Modal .border-text-right {
    border-right: none;
    border-bottom: 1px solid #707070;
  }

  .Purchased_Items_Modal .return-button {
    margin-top: 61%;
  }

  .Purchased_Items_Modal .return-button .return-btn {
    padding: 0px 37px;
    font-size: 16px;
    border: none;
  }

  .Purchased_Items_Modal .return-button .return-btn:hover {
    padding: 0px 37px;
    font-size: 16px;
    border: none;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Purchased_Items_Modal .product-section-div .product-div .product .img-set {
    height: 60px;
  }

  .Purchased_Items_Modal .border-text-right {
    border-right: none;
    border-bottom: 1px solid #707070;
  }

  .Purchased_Items_Modal .return-button {
    margin-top: 63%;
  }

  .Purchased_Items_Modal .return-button .return-btn {
    padding: 0px 37px;
    font-size: 16px;
    border: none;
  }

  .Purchased_Items_Modal .return-button .return-btn:hover {
    padding: 0px 37px;
    font-size: 16px;
    border: none;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Purchased_Items_Modal .product-section-div .product-div .product .img-set {
    height: 90px;
  }

  .Purchased_Items_Modal .border-text-right {
    border-right: none;
    border-bottom: 1px solid #707070;
  }


}