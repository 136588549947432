/* start tab-products section style  */
.tab-products {
  padding-bottom: 4%;
}

.tab-products .first-section  .no-sale-product {
  margin: auto 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.tab-products .first-section  .no-sale-product h1 {
 font-size: 20px;
 color: #000;
 font-weight: 600;
 text-align: center;
}

.tab-products .second-section {
  border: 1px solid #ababab;
  height: 100%;
  border-radius: 10px;
}

.tab-products .second-section .cardBtn .inquiry {
  padding: 8px 9px;
  border-radius: 25px;
  font-size: 14px;
}

.tab-products .second-section .cardBtn .cart {
  padding: 8px 9px;
  border-radius: 25px;
  font-size: 14px;
}

.tab-products .second-section .slider-row .see-more {
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.tab-products .second-section .see-more-align {
  text-align: right;
}

.tab-products .second-section .inner-padding {
  padding: 2% 3%;
  margin-top: 2%;
}

.tab-products .second-section .tabs-bd {
  border-bottom: 1px solid #ababab;
  margin-top: 6px;
}

.tab-products .second-section .tab-menu {
  font-size: 18px;
  color: black;
  font-weight: 500;
}

.tab-products .second-section .nav-pills .nav-link.active,
.tab-products .second-section .nav-pills .show > .tab-menu {
  color: black;
  background-color: white;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 7px solid black;
  border-radius: 0px;
  margin-bottom: -7px;
  /* margin: 0px 0px 0px 15px; */
  margin: 0px 0px 0px 0px;
}

.tab-products .second-section .nav-pills .nav-link.active,
.tab-products .second-section .nav-pills .show > .nav-link {
  color: black;
  background-color: white;
}

.tab-products .first-section {
  border: 2px solid #2b2b2b;
  height: 100%;
  border-radius: 10px;
}

.tab-products .first-section .tabs-bd {
  border-bottom: 1px solid #ababab;
}

.tab-products .first-section h5 {
  font-size: 18px;
  color: #000;
  font-weight: 800;
  margin-bottom: 3px;
}

.tab-products .first-section .content .img-align {
  height: 265px;
  margin: auto;
  display: block;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tab-products .first-section .content .img-set {
  width: 200px;
}

.tab-products .first-section .content .number-align {
  justify-content: center;
  align-items: center;
}

.tab-products .first-section .content .number-align .number {
  font-size: 14px;
  background-color: #f2f2f2;
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 0px;
}

.tab-products .first-section .content .number-align .name {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
}

.tab-products .first-section .content .pro-name {
  font-size: 20px;
  font-weight: 700;
  color: black;
  text-align: center;
  margin-top: 15px;
}

.tab-products .first-section .content .price-align {
  width: 100%;
  text-align: right;
}

.tab-products .first-section .content .price-align .price {
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 0px;
}

.tab-products .first-section .content .price-align .price-span {
  font-size: 15px;
  font-weight: 500;
  color: #979797;
  margin-left: 5px;
  margin-bottom: 0px;
  text-decoration-line: line-through;
}

.tab-products .first-section .content .product-cont {
  /* height: 76px; */
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
  text-align: left;
}

.tab-products .owl-carousel .owl-nav button {
  display: inline-block;
  width: 30px;
  height: 28px;
  background-color: #000 !important;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 5px;
  color: #000;
}

.tab-products .owl-carousel .owl-nav {
  position: absolute;
  right: 15px;
  top: -60px;
}

.featured-slider .owl-carousel .owl-stage {
  margin: auto;
}

.tab-products .second-section {
  border: 1px solid #ababab;
  height: 100%;
  border-radius: 10px;
}

.tab-products .second-section .nav-pills {
  border-bottom: 1px solid #ababab;
  margin-top: 6px;
}

.tab-products .nav-pills .nav-link {
  font-size: 18px;
  color: #727171;
  font-weight: 700;
}

.tab-products .nav-pills .nav-link.active {
  color: black;
  background-color: white;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 7px solid black;
  border-radius: 0px;
  margin-bottom: -7px;
}

/* first arrow css */
.tab-products .first-section .main-slider {
  position: relative;
}

.tab-products .first-section .main-slider .arrow-back,
.tab-products .first-section .main-slider .arrow-next {
  cursor: pointer;
}

.tab-products .first-section .main-slider .main-arrow-back {
  position: absolute;
  top: 11px;
  right: 54px;
  z-index: 1;
  cursor: pointer;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 31px;
  height: 28px;
}

.tab-products .first-section .main-slider .main-arrow-back:hover,
.tab-products .first-section .main-slider .main-arrow-next:hover {
  background-color: #359271;
  transition-delay: 0.2s;
}

.tab-products .first-section .main-slider .arrow-back {
  content: url("/public/assets/Image/Icon/previous.png");
  width: 15px;
  margin: 9px;
}

.tab-products .first-section .main-slider .main-arrow-next {
  position: absolute;
  top: 11px;
  right: 12px;
  z-index: 1;
  cursor: pointer;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 31px;
  height: 28px;
}

.tab-products .first-section .main-slider .arrow-next {
  content: url("/public/assets/Image/Icon/next.png");
  width: 15px;
  margin: 9px;
}

/* second arrow css */
.tab-products .second-buttons .main-slider {
  position: relative;
}

.tab-products .second-buttons .main-slider .arrow-back,
.tab-products .second-buttons .main-slider .arrow-next {
  cursor: pointer;
}

.tab-products .second-buttons .main-slider .main-arrow-back {
  position: absolute;
  top: 11px;
  right: 54px;
  z-index: 1;
  cursor: pointer;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 31px;
  height: 28px;
}

.tab-products .second-buttons .main-slider .main-arrow-back:hover,
.tab-products .second-buttons .main-slider .main-arrow-next:hover {
  background-color: #359271;
  transition-delay: 0.2s;
}

.tab-products .second-buttons .main-slider .arrow-back {
  content: url("/public/assets/Image/Icon/previous.png");
  width: 15px;
  margin: 9px;
}

.tab-products .second-buttons .main-slider .main-arrow-next {
  position: absolute;
  top: 11px;
  right: 12px;
  z-index: 1;
  cursor: pointer;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 31px;
  height: 28px;
}

.tab-products .second-buttons .main-slider .arrow-next {
  content: url("/public/assets/Image/Icon/next.png");
  width: 15px;
  margin: 9px;
}

.tab-products .second-buttons .main-slider .main-arrow-back.disabled {
  background: gray;
  cursor: not-allowed;
}

@media (min-width: 0px) and (max-width: 768px) {
  .tab-products .first-section .main-slider .arrow-back {
    width: 11px;
  }

  .tab-products .first-section .main-slider .arrow-next {
    width: 11px;
  }

  .tab-products .first-section .main-slider .main-arrow-back {
    width: 27px;
    height: 25px;
    right: 46px;
  }

  .tab-products .first-section .main-slider .main-arrow-next {
    width: 27px;
    height: 25px;
  }

  .tab-products .second-buttons .main-slider .arrow-back {
    width: 11px;
  }

  .tab-products .second-buttons .main-slider .main-arrow-back {
    width: 27px;
    height: 25px;
    right: 43px;
  }

  .tab-products .second-buttons .main-slider .main-arrow-next {
    width: 27px;
    height: 25px;
  }

  .tab-products .second-buttons .main-slider .arrow-next {
    width: 11px;
    margin: 9px;
  }

  .tab-products .second-section .nav-pills .nav-link.active {
    margin: 0px 0 0 2px;
  }
}

@media (max-width: 320px) {
  .tab-products .second-section .cardBtn .inquiry {
    padding: 5px 14px;
    font-size: 9px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 5px 14px;
    font-size: 9px;
  }

  /* .tab-products .first-section .content .img-set {
    width: 35%;
  } */
  .tab-products .first-section .content .img-set {
    width: 160px;
  }

  .tab-products .first-section .content .img-align {
    height: 205px;
  }

  .tab-products .space {
    margin-bottom: 10px;
  }

  .tab-products .second-section .tab-menu {
    font-size: 12px;
    padding: 10px 10px;
  }

  .tab-products .second-section .nav-pills .nav-link.active,
  .tab-products .second-section .nav-pills .show > .tab-menu {
    font-size: 12px;
    font-weight: 700;
  }

  .tab-products #btn-text {
    display: none;
  }

  .tab-products .owl-carousel .owl-nav {
    display: none;
  }

  .tab-products .first-section .content .pro-name {
    font-size: 16px;
  }

  .tab-products .second-section .card-section .product-details .pro-name {
    font-size: 16px;
  }

  .tab-products .second-section .card-section .product-details .pro-price {
    font-size: 15px;
  }

  .tab-products .second-section .card-section .product-info-p {
    font-size: 11px;
  }

  .tab-products .second-section .card-section .bd-right {
    border-right: 1px solid #ababab;
  }

  .tab-products .nav-pills .nav-link {
    font-size: 12px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .tab-products .second-section .cardBtn .inquiry {
    padding: 5px 20px;
    font-size: 10px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 5px 20px;
    font-size: 10px;
  }

  /* .tab-products .first-section .content .img-set {
    width: 32%;
  } */
  .tab-products .second-section .nav-pills .nav-link.active,
  .tab-products .second-section .nav-pills .show > .tab-menu {
    margin: 0px 0px 0px 0px;
  }

  .tab-products .first-section .content .img-set {
    width: 160px;
  }

  .tab-products .first-section .content .img-align {
    height: 205px;
  }

  .tab-products .space {
    margin-bottom: 10px;
  }

  .tab-products .second-section .tab-menu {
    font-size: 14px;
    padding: 10px 10px;
  }

  .tab-products .second-section .nav-pills .nav-link.active,
  .tab-products .second-section .nav-pills .show > .tab-menu {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 6px;
  }

  .tab-products #btn-text {
    display: none;
  }

  .tab-products .owl-carousel .owl-nav {
    display: none;
  }

  .tab-products .first-section .content .pro-name {
    font-size: 16px;
  }

  .tab-products .second-section .card-section .product-details .pro-name {
    font-size: 16px;
  }

  .tab-products .second-section .card-section .product-details .pro-price {
    font-size: 15px;
  }

  .tab-products .second-section .card-section .product-info-p {
    font-size: 11px;
  }

  .tab-products .second-section .card-section .bd-right {
    border-right: 1px solid #ababab;
  }

  .tab-products .nav-pills .nav-link {
    font-size: 14px;
    padding: 10px 10px;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  /* .tab-products .space {
    margin-bottom: 10px;
  } */
}

@media (min-width: 575px) and (max-width: 767.98px) {
  .tab-products .space {
    margin-bottom: 10px;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .tab-products .second-section .cardBtn .inquiry {
    padding: 5px 20px;
    font-size: 10px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 5px 20px;
    font-size: 10px;
  }

  /* .tab-products .first-section .content .img-set {
    width: 32%;
  } */

  .tab-products .first-section .content .img-align {
    height: 213px;
  }

  .tab-products .space {
    margin-bottom: 10px;
  }

  .tab-products .second-section .tab-menu {
    font-size: 14px;
    padding: 10px 10px;
  }

  .tab-products .second-section .nav-pills .nav-link.active,
  .tab-products .second-section .nav-pills .show > .tab-menu {
    font-size: 14px;
    font-weight: 700;
  }

  .tab-products #btn-text {
    display: none;
  }

  .tab-products .owl-carousel .owl-nav {
    display: none;
  }

  .tab-products .first-section .content .pro-name {
    font-size: 16px;
  }

  .tab-products .second-section .card-section .product-details .pro-name {
    font-size: 16px;
  }

  .tab-products .second-section .card-section .product-details .pro-price {
    font-size: 15px;
  }

  .tab-products .second-section .card-section .product-info-p {
    font-size: 11px;
  }

  .tab-products .second-section .card-section .bd-right {
    border-right: 1px solid #ababab;
  }

  .tab-products .nav-pills .nav-link {
    font-size: 14px;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .tab-products .space {
    margin-bottom: 10px;
  }

  /* .tab-products .first-section .content .img-set {
    width: 25%;
  } */

  .tab-products .second-section .card-section .bd-right {
    border-right: 1px solid #ababab;
  }

  .tab-products .nav-pills .nav-link {
    font-size: 14px;
  }

  .tab-products .second-section .nav-pills .nav-link.active,
  .tab-products .second-section .nav-pills .show > .tab-menu {
    font-size: 14px;
  }

  /* .tab-products .first-section .content .img-set {
    width: 25%;
  } */

  .tab-products .second-section .card-section .bd-right {
    border-right: 1px solid #ababab;
    height: 226px;
    margin: auto;
  }

  .tab-products .second-section .card .product-img {
    width: 85%;
  }

  .tab-products .second-section .cardBtn .inquiry {
    padding: 8px 20px;
    font-size: 10px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 8px 20px;
    font-size: 10px;
  }

  .tab-products .second-section .cardBtn #btn-text {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  /* .tab-products .first-section .content .img-set {
    width: 40%;
  } */

  .tab-products .first-section .content .img-align {
    height: 265px;
  }

  .tab-products .second-section .cardBtn .inquiry {
    padding: 6px 5px;
    font-size: 10px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 6px 5px;
    font-size: 10px;
  }

  .tab-products .space {
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  /* .tab-products .first-section .content .img-set {
    width: 45%;
  } */

  .tab-products .second-section .cardBtn .inquiry {
    padding: 6px 5px;
    font-size: 10px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 6px 5px;
    font-size: 10px;
  }

  .tab-products .space {
    margin-bottom: 10px;
  }

  .tab-products .first-section .content .number-align .number {
    font-size: 11px;
  }

  .tab-products .first-section .content .number-align .name {
    font-size: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .tab-products .second-section .cardBtn .inquiry {
    padding: 5px 7px;
    font-size: 11px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 5px 7px;
    font-size: 11px;
  }

  .tab-products .first-section .content .number-align .number {
    font-size: 10px;
  }

  .tab-products .first-section .content .number-align .name {
    font-size: 10px;
  }

  .tab-products .first-section .content .pro-name {
    font-size: 16px;
  }

  .tab-products .first-section .content .product-cont {
    font-size: 11px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
  }

  .tab-products .first-section .content .price-align .price {
    font-size: 13px;
  }

  .tab-products .first-section .content .price-align .price-span {
    font-size: 13px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .tab-products .second-section .cardBtn .inquiry {
    padding: 8px 14px;
    font-size: 13px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 8px 14px;
    font-size: 13px;
  }

  /* .tab-products .first-section {
    height: 90%;
  } */

  /* .tab-products .second-section {
    height: 90%;
  } */
}

@media (min-width: 1441px) and (max-width: 1680px) {
  .tab-products .second-section .cardBtn .inquiry {
    padding: 8px 5px;
    font-size: 10px;
  }

  .tab-products .second-section .cardBtn .cart {
    padding: 8px 5px;
    font-size: 10px;
  }
}

@media (min-width: 2000px) and (max-width: 2700px) {
  /* .tab-products .first-section .content .img-set {
    width: 20%;
  } */
}
