
.related-prodcut .title-related-product .relate-prod{
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    padding-left: 23px;
}

.related-prodcut  span.swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
    background-color: #359271;
}
.carrrbtttt{
    background-color: transparent !important;
    border: 0px !important;
}
@media screen and (min-width: 0px) and (max-width: 768px)
{
    .related-prodcut .title-related-product .relate-prod{
        font-size: 18px;
        
    }
}