.Cancel_Reason_Modal .modal-header {
  background: #0f3768 0% 0% no-repeat padding-box;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.Cancel_Reason_Modal .modal-content {
  border: none;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 17px #00000012;
  border-radius: 10px;
}

.Cancel_Reason_Modal .modal-body {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 17px #00000012;
  border-radius: 10px;
}

.Cancel_Reason_Modal .modal-body .select_reason {
  padding: 0px 0px 0px 80px;
}

.Cancel_Reason_Modal .modal-body .select_reason .form-check {
  margin-bottom: 7px;
}

.Cancel_Reason_Modal .modal-body .select_reason .form-check-label {
  font-size: 16px;
  font-weight: 500;
  color: #323232;
}

.Cancel_Reason_Modal .modal-body .select_reason .form-check-input:checked {
  background-color: #000;
  border-color: #000;
}

.Cancel_Reason_Modal .modal-header .btn-close {
  filter: brightness(0) invert(1);
  opacity: 1;
}

@media (min-width: 280px) and (max-width: 768px) {
  .Cancel_Reason_Modal .modal-body .select_reason {
    padding: 0px;
  }

  .Cancel_Reason_Modal .modal-body .select_reason .form-check-label {
    font-size: 14px;
  }
}