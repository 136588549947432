.blog-section {
  padding: 50px 0px;
}

.blog-section .blog-rights {
  padding: 12px;
  border: 0.5px solid #b5b5b5;
  box-shadow: 0px 0px 24px #00000012;
  border-radius: 5px;
  opacity: 1;
  margin-bottom: 16px;
  position: relative;
}

.blog-section .blog-rights .rights-imgdiv {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s;
  border-radius: 5px;
}

.blog-section .blog-rights .rights-imgdiv .rightimg {
  width: 100%;
  height: 325px;
  overflow: hidden;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.blog-section .blog-rights .rights-imgdiv .rightimg:hover {
  transform: scale(1.1);
  overflow: hidden;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.blog-section .blog-rights .blog-rightcontet .blogdate {
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #949494;
  margin-bottom: 10px;
  opacity: 1;
}

.blog-section .blog-rights .blog-rightcontet .blogheads {
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.blog-section .blog-rights .blog-rightcontet .blogtxts {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #5d5d5d;
  margin-bottom: 10px;
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 11;
  text-align: justify;
}

.blog-section .blog-rights .blog-rightcontet .readmore {
  text-align: right;
  font-size: 12px;
  line-height: 22px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #359271;
  padding-top: 17px;
  margin: 0;
  opacity: 1;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.blog-section .blog-rights .blog-rightcontet .aro {
  margin-left: 7px;
  margin-bottom: -1px;
}

.blog-section .scoll {
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 24px #00000012;
  height: 360px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.blog-section .scoll .scroll-image {
  width: 100%;
  aspect-ratio: 1/1;
}

.blog-section .scoll .scroll-top-con .top-p {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #323232;
  margin-bottom: 5px;
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.blog-section .scoll .scroll-top-con .scroll-bottom-con {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.blog-section .scoll .heads h5 {
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.blog-section .datecreate{

  font-size: 14px;
  color: #8b8989;
}

@media (min-width: 0px) and (max-width: 767px) {
  .blog-section .blog-rights .rights-imgdiv .rightimg {
    width: 100%;
    height: auto;
  }

  .blog-section .blog-rights .blog-rightcontet {
    padding-top: 15px;
  }

  .blog-section .blog-rights .blog-rightcontet .blogheads {
    font-size: 16px;
    line-height: 15px;
  }

  .blog-section .blog-rights .blog-rightcontet .blogtxts {
    font-size: 13px;
    line-height: 18px;
  }

  .blog-section .blog-rights .blog-rightcontet .readmore {
    font-size: 11px;
    line-height: 15px;
    padding-top: 15px;
  }

  .blog-section .scoll {
    padding: 15px;
  }

  .blog-section .scoll .scroll-top-con .top-p {
    font-size: 14px;
  }

  .blog-section .scoll .scroll-top-con .scroll-bottom-con {
    font-size: 13px;
  }

  .blog-section .scoll .heads h5 {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1200px) {
  .blog-section .scoll {
    padding: 20px 15px;
  }
}
