.Successfull_Modal .modal-header {
  border: none;
}

.Successfull_Modal .modal-body {
  border: none;
}

.Successfull_Modal .modal-body p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.ninjas {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: #359271;
}
