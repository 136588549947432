.form-section .account-form {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 30px;
    margin-top: 20px;
    border-radius: 5px;
}

.account-form .form-title {
    font-size: 16px;
    font-weight: 500;
    color: #323232;
}

.account-form a {
    text-decoration: none;
}
.fcontrl{
    position: relative;
}

.account-form .edit {
    font-size: 14px;
    color: #F05340;
    font-weight: 600;
}

.account-form .label-set {
    font-size: 14px;
    font-weight: 500;
    color: #323232;
}

.account-form .form-control {
    font-size: 14px;
    /* background-color: #F6F6F6; */
    /* border: 0px solid #ced4da; */
}

.account-form .form-control:focus {
    box-shadow: none;
    background-color: #F6F6F6;
}

.account-form .update,
.account-form .update:hover {
  color: #fff;
  background-color: #f05340;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 50px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
}


@media (max-width:484px) {
    .account-information .title {
        font-size: 16px;
        padding: 10px;
    }

    .form-section .account-form {
        padding: 20px;
    }

    .account-form .form-title {
        font-size: 16px;
    }

    .account-form .label-set {
        font-size: 13px;
    }

    .account-form .edit {
        font-size: 12px;
    }
}


@media (min-width:485px) and (max-width:574px) {
    .account-information .title {
        font-size: 20px;
    }

    .account-form .form-title {
        font-size: 18px;
    }
}

@media (min-width:0px) and (max-width:767px) {
    
}

@media (min-width:768px) and (max-width:991px) {}

@media (min-width:992px) and (max-width:1023px) {}

@media (min-width:1024px) and (max-width:1199px) {}

@media (min-width:1200px) and (max-width:1399px) {}