.select-Color .modal-header {
    border-bottom: 0px;
}
.select-Color .modal-footer {
    border-top: 0px;
}
.Main-Color-Selection {
    margin: 3px 39px;
    border-radius: 10px;
}
.select-Color img.shopping-cart-img {
    height: 20px;
    width: 20px;
}
.select-Color .circle1 {
    margin: 5px 0px;
    background-color: red;
    width: 15px;
    height: 15px;
    border-radius: 100px;
}
.select-Color .color-name-red {
    color: red;
}
.select-Color .circle2 {
    margin: 5px 0px;
    background-color: blue;
    width: 15px;
    height: 15px;
    border-radius: 100px;
}
.select-Color .color-name-blue {
    color: blue;
}

.select-Color .circle3 {
    margin: 5px 0px;
    background-color: rgb(255, 213, 0);
    width: 15px;
    height: 15px;
    border-radius: 100px;
}
.select-Color .color-name-yellow {
    color: rgb(255, 213, 0);
}
.select-Color .colors {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
}
.select-Color li.grey {
    background-color: #f1f1f1;
    list-style-type: none;
    border-bottom: 1px solid #c7c3c3;
}
.select-Color li.white {
    background-color: white;
    list-style-type: none;
    border-bottom: 1px solid #c7c3c3;
}
.select-Color ul.main-body {
    padding-left: 0px;
    border-radius: 0px;
    border: 1px solid #b9b8b8;
}
.select-Color .modal-content {
    border-radius: 21px;
}
.select-Color .cart {
    position: relative;
}
.select-Color .shopping-img-align {
    position: absolute;
    top: 0px;
    right: 30px;
}
.select-Color button.btn.btn-primary {
    background-color: transparent;
    border: none;
}