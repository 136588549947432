.prodcut-description .nav-pills .nav-link.active {
  color: black;
  background-color: white;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 2px solid black;
  border-radius: 0px;
  padding: 10px 0px 5px 0px;
}

.prodcut-description .nav-pills .nav-link {
  color: #949494;
  font-size: 15px;
  font-weight: 700;
  margin: 0px 25px 0px 0px;
  padding: 10px 0px 5px 0px;
}
.star-icon{
  color: #ffc107 !important;
}
.prodcut-description .prodct-desc-text {
  font-size: 14px;
  text-align: justify;
  margin-top: 13px;
}

.file-img-holder {
  position: relative;
  display: flex;
  /* Use flex to ensure proper layout */
  flex-direction: row;
  align-items: center;
  /* Center align items vertically */
}

.prodcut-description .blk-border {
  border-bottom: 2px solid #b2b2b2;
  margin-bottom: 5%;
}

.add-review .rate-btn {
  background-color: #dcdcdc;
  color: black;
  font-size: 14px;
  padding: 7px 20px;
  border-radius: 5px;
  width: fit-content;
}

.border-bottom-rate {
  margin-top: 10px;
  border-bottom: 2px solid #707070;
}

.rating-text-p {
  font-size: 14px;
  padding-top: 2%;
  margin-bottom: 0px;
}

.add-review .review-form-sec .rating-star {
  /* width: 20px; */
  margin: 1px;
}

.add-review .review-form-sec .rating-star svg.star-svg {
  width: 27px;
}

.review-form-sec .review-form-text {
  font-size: 14px;
}

.review-form-sec .review-form-text {
  font-size: 14px;
}

.review-form-sec .text-area-bg {
  background-color: #f6f6f6;
  border: 0px;
  font-size: 14px;
  height: 100px;
}

.prodcut-description .cancel-btn,
.prodcut-description .cancel-btn:hover {
  background-color: #b7b7b7;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  padding: 7px 0px;
  width: 100px;
  border: none;
  margin: 5px;
}

.prodcut-description .post-btn, 
.prodcut-description .post-btn:hover {
  background-color: #000;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  padding: 7px 0px;
  width: 100px;
  margin: 5px;
  border: none;
}

.review-box .card-div-content {
  padding: 15px;
}

.prodcut-description .tab3-content .card-div-content .name {
  font-size: 20px;
  font-weight: 800;
}

.prodcut-description .tab3-content .card-div-content .review {
  font-size: 12px;
  margin-bottom: 5px;
  color: #787878;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.review-form-sec .add-img {
  width: 60px;
  height: 60px;
}

.review-form-sec .add-div .add {
  width: 39px;
}

.review-form-sec .add-div {
  width: 60px;
  height: 60px;
  border: 1px solid #359271;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 13px;
}

.prodcut-description .tab3-content .card-div .date-align p {
  font-size: 10px;
  background-color: #f2f2f2;
  padding: 2px 5px;
  margin-bottom: 0px;
  margin-left: auto;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50px;
  margin-top: 5px;
  margin-right: 5px;
}

.review-form-sec .badge-cancel {
  background-color: #000;
  color: #fff;
  font-size: 13px;
  border-radius: 30px;
  position: absolute;
  bottom: 45px;
  left: 50px;
  transform: rotate(45deg);
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.review-form-sec .add-img-div {
  position: relative;
}

.review-form-sec .add-img-div .cross-icon {
  background-color: #000;
  color: #fff;
  width: 15px;
  height: 15px;
  font-size: 11px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -5px;
  border: none;
}

.card-div .date-align .star-div .review-box-img svg.star-svg {
  width: 12px;
  height: 12px;
}
.card-div .d-flex.mx-auto.star-div {
color: #ffc107;
}

.prodcut-description .tab3-content .load-more .loadMore.btn {
  text-decoration: underline;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .prodcut-description .nav-pills .nav-link.active {
    font-size: 14px;
  }

  .prodcut-description .nav-pills .nav-link {
    font-size: 14px;
  }

  .prodcut-description .cancel-btn,
  .prodcut-description .cancel-btn:hover {
    font-size: 12px;
    width: 70px;
  }

  .prodcut-description .post-btn,
  .prodcut-description .post-btn:hover {
    font-size: 12px;
    width: 70px;
  }

  .prodcut-description .tab3-content .card-div-content .name {
    font-size: 16px;
  }

  .review-form-sec .review-form-text {
    font-size: 12px;
  }

  .add-review .review-form-sec .rating-star svg.star-svg {
    width: 23px;
  }
}
