.LogIn .bg-color {
  display: flex;
  justify-content: center;
  height: 100%;
}

.LogIn .img-section {
  background: #359271 0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 0px 25px;
  position: relative;
}

.LogIn .img-section .img-holder {
  /* padding-top: 50px; */
  left: -20%;
  position: absolute;
  /* height: 100vh; */
}

.LogIn .img-section .img-holder .log_in_img {
  /* width: 100%;
  height: 100%; */

  height: 311px;
  object-fit: contain;
  width: 100%;
}

/*LogIn form css start*/
.LogIn .bg-color .back_to_span p {
  font-size: 16px;
  font-weight: 500;
  color: #359271;
  text-align: center;
}

.LogIn .back_to_span {
  padding-top: 119px;
}

.LogIn .back_to_span2 {
  padding-top: 50px !important;
}

.LogIn .bg-color .heading-holder {
  margin-top: 20%;
}

.LogIn .bg-color .heading-holder2 {
  margin-top: 8% !important;
}

.LogIn .bg-color .heading-holder3 {
  margin-top: 37% !important;
}

.LogIn .bg-color .heading-holder h4 {
  font-size: 25px;
  font-weight: 600;
  color: #2b2b2b;
  text-align: center;
}

.LogIn .bg-color .heading-holder p {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #949494;
}

.LogIn .bg-color .log_in_form {
  margin-top: 5%;
  padding-bottom: 20px;
}

.LogIn .inputdiv {
  padding: 0px 30px;
}

.LogIn .bg-color .log_in_form .form-control {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.LogIn .bg-color .log_in_form .form-control:focus {
  box-shadow: none;
}

.LogIn .bg-color .log_in_form .Forgot_password {
  font-size: 16px;
  font-weight: 500;
  color: #065bde;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.LogIn .bg-color .log_in_form .btn-holder {
  text-align: center;
  margin-top: 10%;
}

.LogIn .bg-color .log_in_form .btn-holder .submit_btn {
  background: #212121 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: #000;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 5px 40px;
}

.LogIn .bg-color .log_in_form .create_account {
  margin-top: 3%;
}

.LogIn .bg-color .log_in_form .create_account p {
  
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  color: #359271;
  cursor: pointer;
}
.LogIn .bg-color .log_in_form .crttaccount{
    text-align: -webkit-center;
}



.LogIn .bg-color .log_in_form .create_account .cancel-text {
  text-align: center;
  text-decoration: underline;
  font-size: 15px;
  display: flex;
  font-weight: 400;
  color: #323232;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.LogIn .bg-color .log_in_form .create_account .login_text p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  margin: 0px auto;
}

.LogIn .bg-color .log_in_form .create_account .login_text span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #359271;
  cursor: pointer;
}

.LogIn .bg-color .log_in_form .log_in_with .log_in_with {
  margin-top: 10%;
}

.LogIn .bg-color .log_in_form .log_in_with p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #979797;
  display: flex;
}

.LogIn .bg-color .log_in_form .log_in_with p:before,
.LogIn .bg-color .log_in_form .log_in_with p:after {
  color: white;
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
  margin: auto 5px;
}

.LogIn .bg-color .log_in_form .social_img_holder {
  text-align: center;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 50%;
  margin: auto;
}

.LogIn .bg-color .log_in_form .social_img_holder .social_img {
  height: 22px;
  width: 22px;
}

.LogIn .bg-color .log_in_form .password-field-holder {
  position: relative;
}

.LogIn .bg-color .log_in_form .eye-icon-holder {
  position: absolute;
  top: 0;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LogIn .bg-color .log_in_form .eye-icon {
  font-size: 15px;
  color: #6c757d;
  position: absolute;
  right: 15px;
  top: 10px;
}

/*LogIn form css start*/
@media (min-width: 0px) and (max-width: 320px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder3 {
    margin-top: 15% !important;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section {
    min-height: 50vh;
    padding: 0px 0%;
    /* display: none; */
  }

  .LogIn .back_to_span {
    padding-top: 20px;
  }

  .LogIn .img-section .img-holder {
    left: 0;
    right: 0;
  }

  .LogIn .img-section .img-holder .log_in_img {
    height: 200px;
  }

  .LogIn .bg-color .log_in_form .Forgot_password {
    font-size: 13px;
  }

  .LogIn .bg-color .back_to_span p {
    font-size: 14px;
  }

  .LogIn .bg-color .log_in_form .log_in_with p {
    font-size: 13px;
  }

  .LogIn .bg-color .heading-holder p {
    font-size: 12px;
  }

  .LogIn .bg-color .log_in_form .form-control {
    font-size: 13px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder3 {
    margin-top: 15% !important;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section {
    min-height: 50vh;
    padding: 0px 0%;
    /* display: none; */
  }

  .LogIn .back_to_span {
    padding-top: 25px;
  }

  .LogIn .img-section .img-holder {
    left: 0;
    right: 0;
  }

  .LogIn .img-section .img-holder .log_in_img {
    height: 200px;
  }

  .LogIn .bg-color .log_in_form .Forgot_password {
    font-size: 13px;
  }

  .LogIn .bg-color .back_to_span p {
    font-size: 14px;
  }

  .LogIn .bg-color .log_in_form .log_in_with p {
    font-size: 13px;
  }

  .LogIn .bg-color .heading-holder p {
    font-size: 12px;
  }

  .LogIn .bg-color .log_in_form .form-control {
    font-size: 13px;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder3 {
    margin-top: 15% !important;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section {
    min-height: 50vh;
    padding: 0px 0%;
    /* display: none; */
  }

  .LogIn .back_to_span {
    padding-top: 25px;
  }

  .LogIn .img-section .img-holder {
    left: 0;
    right: 0;
  }

  .LogIn .img-section .img-holder .log_in_img {
    height: 200px;
  }

  .LogIn .bg-color .log_in_form .Forgot_password {
    font-size: 14px;
  }

  .LogIn .bg-color .back_to_span p {
    font-size: 14px;
  }

  .LogIn .bg-color .log_in_form .log_in_with p {
    font-size: 14px;
  }

  .LogIn .bg-color .heading-holder p {
    font-size: 13px;
  }

  .LogIn .bg-color .log_in_form .form-control {
    font-size: 13px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder3 {
    margin-top: 15% !important;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section {
    min-height: 50vh;
    padding: 0px 0%;
    /* display: none; */
  }

  .LogIn .back_to_span {
    padding-top: 25px;
  }

  .LogIn .img-section .img-holder {
    left: 0;
    right: 0;
  }

  .LogIn .img-section .img-holder .log_in_img {
    height: 200px;
  }

  .LogIn .bg-color .log_in_form .Forgot_password {
    font-size: 14px;
  }

  .LogIn .bg-color .back_to_span p {
    font-size: 15px;
  }

  .LogIn .bg-color .log_in_form .log_in_with p {
    font-size: 14px;
  }

  .LogIn .bg-color .heading-holder p {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .back_to_span {
    padding-top: 50px;
  }

  .LogIn .bg-color .log_in_form .Forgot_password {
    font-size: 15px;
  }

  .LogIn .bg-color .log_in_form .log_in_with p {
    font-size: 15px;
  }

  .LogIn .bg-color .heading-holder p {
    font-size: 13px;
  }
}
