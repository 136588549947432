.AboutBanner-section .aboutbanner .about-img {
  width: 100%;
  height: 100%;
  /* height: 600px; */
  /* object-fit: cover; */
  border-radius: 15px;
  margin: 15px 0px;
}

@media (min-width: 0px) and (max-width: 768px) {
  /* .AboutBanner-section .aboutbanner .about-img {
    height: 240px;
} */
}
