.counter-section {
  margin: 3% 0% 3% 0%;
  padding: 50px;
  background-color: #19875433;
}

.counter-section .counter-circle {
  height: 222px;
  width: 222px;
  background-color: #000;
  border-radius: 130px;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.counter-section .counter-circle .numbers {
  color: #fff;
  font-size: 51px;
  font-weight: 600;
  margin: 0;
}

.counter-section .counter-text {
  font-size: 18px;
  font-weight: 600;
  margin-top: 25px;
}

@media (max-width: 320px) {
  .counter-section .counter-circle {
    height: 70px;
    width: 70px;
  }

  .counter-section .counter-circle .numbers {
    font-size: 16px;
  }

  .counter-section .counter-text {
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .counter-section .counter-circle {
    height: 100px;
    width: 100px;
  }

  .counter-section .counter-circle .numbers {
    font-size: 20px;
  }

  .counter-section .counter-text {
    font-size: 12px;
  }
}

@media (min-width: 485px) and (max-width: 574px) {
  .counter-section .counter-circle {
    height: 100px;
    width: 100px;
  }

  .counter-section .counter-circle .numbers {
    font-size: 20px;
  }

  .counter-section .counter-text {
    font-size: 15px;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .counter-section .counter-circle {
    height: 100px;
    width: 100px;
  }

  .counter-section .counter-circle .numbers {
    font-size: 20px;
  }

  .counter-section .counter-text {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .counter-section .counter-circle {
    height: 150px;
    width: 150px;
  }

  .counter-section .counter-circle .numbers {
    font-size: 35px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .counter-section .counter-circle {
    height: 200px;
    width: 200px;
  }
}