
.hearticn {
    width: 36px;
    height: 36px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 4px 4px;
    margin-left: auto;
    padding: 10px 0px;
    cursor: pointer;
}
  
  .hearticn .gobiheart {
    font-size: 18px;
    color: #b4b2b2;
  }
  .hearticn .gobiheartss{
    font-size: 18px;
    color: #df4223;
  }