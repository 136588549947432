.contactus-section {
}

.contactus-section .contactusmain .gmaps {
  width: 100%;
}

.contactus-section .contactusmain .mapss {
  width: 100% !important;
  height: 650px;
}

.contactus-section .contactusmain .contacts {
  display: flex;
  margin-bottom: 25px;
}

.contactus-section .contactusmain .form-holder{
  background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 24px #00000012;
    border-radius: 6px;
    opacity: 1;
    padding: 30px 23px 70px 30px;
}

.contactus-section .contactusmain .iconss {
  font-size: 22px;
  color: #359271;
  margin-right: 25px;
}

.contactus-section .contactusmain .contact-headss h5 {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 20px 0px;
}

.contactus-section .contactusmain .contacttxts p {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.contactus-section .contactusmain .blogform {
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #00000012;
  border-radius: 6px;
  opacity: 1;
  padding: 30px 23px 70px 30px; */
}

.contactus-section .contactusmain .blogform .sentbtn {
  background: #212121 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: 0.41px;
  color: #ffffff;
  opacity: 1;
  width: 185px;
  height: 38px;
  float: right;
  border: none;
}

.contactus-section .contactusmain .websites-div {
  padding-top: 30px;
  border-top: 1px solid #575656;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 70px;
}

.contactus-section .contactusmain .websites-div .websites {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 15px;
}

.contactus-section .contactusmain .websites-div .face {
  background: #1877f2 0% 0% no-repeat padding-box;
}

.contactus-section .contactusmain .websites-div .linked {
  background: #007ab9 0% 0% no-repeat padding-box;
}

.contactus-section .contactusmain .websites-div .pina {
  background: #cb2027 0% 0% no-repeat padding-box;
}

.contactus-section .contactusmain .websites-div .tiwt {
  background: #03a9f4 0% 0% no-repeat padding-box;
}

.contactus-section .contactusmain .websites-div .insta {
  background: #f05340 0% 0% no-repeat padding-box;
}



.contactus-section .contactusmain .form-control {
  font: normal normal normal 14px/16px Arial;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #a2a2a2;
  opacity: 1;
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 10px;
  border: none;
}

@media (min-width: 0px) and (max-width: 767px) {
  .contactus-section .contactusmain .mapss {
    height: 250px;
  }

  .contactus-section .contactusmain .contact-headss h5 {
    font-size: 20px;
    line-height: 18px;
    margin: 13px 0px;
  }

  .contactus-section .contactusmain .contacts {
    display: flex;
    margin-bottom: 15px;
  }

  .contactus-section .contactusmain .iconss {
    font-size: 14px;
    margin-right: 17px;
  }

  .contactus-section .contactusmain .blogform .sentbtn {
    font-size: 12px;
    line-height: 16px;
    width: 160px;
    height: 35px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contactus-section .contactusmain .mapss {
    height: 380px;
  }

  .contactus-section .contactusmain .contact-headss h5 {
    font-size: 22px;
    line-height: 18px;
    margin: 16px 0px;
  }

  .contactus-section .contactusmain .contacts {
    display: flex;
    margin-bottom: 18px;
  }

  .contactus-section .contactusmain .iconss {
    font-size: 20px;
    margin-right: 18px;
  }

  .contactus-section .contactusmain .contacttxts p {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0px;
  }

  .contactus-section .contactusmain .blogform .sentbtn {
    font-size: 13px;
    line-height: 16px;
    width: 170px;
    height: 38px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .contactus-section .contactusmain .contact-headss h5 {
    font-size: 22px;
    line-height: 22px;
    margin: 20px 0px;
  }

  .contactus-section .contactusmain .iconss {
    font-size: 20px;
    margin-right: 22px;
  }

  .contactus-section .contactusmain .contacts {
    margin-bottom: 19px;
  }
}
