.view-cart-btn {
  font-size: 14px;
  text-decoration: underline;
  color: #359271;
  font-weight: 600;
  border: none;
  background: transparent;
}
.lottimodal .modal-header {
  border: none;
  padding: 14px 14px 0px 0px;
}

.modal-text-class {
  font-size: 18px;
}

.Logout-btn {
  font-size: 14px;
  color: #fff;
  background: #359271;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
}

.cancel-btn {
  font-size: 14px;
  color: #fff;
  background: #212121;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
}

.modal-text-class .netpurti-btn {
  font-size: 18px;
  color: #359271;
}
