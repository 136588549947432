.footer-section .footer-bg {
  background-color: #212121;
  padding: 50px 0px 20px 0px;
}

.footer-section .footer-logo {
  width: 140px;
  height: 129px;
}

.footer-section .font-icon {
  color: #359271;
}

.footer-section .footer-fafa {
  color: #359271;
  font-size: 22px;
}

.footer-section .foot-p {
  color: #fff;
  font-size: 12px;
  margin: 3px 0px 5px 20px;
  cursor: pointer;
}

.footer-section .foot-title {
  color: #fff;
  font-size: 20px;
}

.footer-section .feature-div {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-weight: 500;
  cursor: pointer;
}

.feature-fafa {
  width: 19px;
  filter: hue-rotate(82deg);
}

.feature-fafa-subtext {
  margin: 3px 0px 0px 10px;
  font-size: 14px;
  color: #000;
}

.footer-submenu a,
.footer-submenu a:hover {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  /* line-height: 7px; */
}

.download-btn-img {
  width: 67%;
  margin-bottom: 10px;
}

.copyright-p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0px;
  margin-top: 20px;
}

.copyright-span,
.copyright-span:hover {
  color: #359271;
  text-decoration: none;
  font-weight: 500;
}

.border-foot {
  border-bottom: 1px solid #fff;
  width: 85%;
  margin: auto;
}

.footer-section .footer-col .Social_Media_Icon_holder {
  display: flex;
}

.footer-section .footer-col .Social_Media_Icon_holder .font-icon {
  color: #fff;
  margin-right: 12px;
  font-size: 15px;
  width: 20px;
  height: 20px;
}

@media (max-width: 575px) {
  .feature-fafa-subtext {
    font-size: 12px;
  }

  .footer-submenu a,
  .footer-submenu a:hover {
    font-size: 12px;
  }

  .footer-section .foot-title {
    font-size: 16px;
    margin-top: 15px;
  }

  .copyright-p {
    font-size: 10px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .footer-section .foot-title {
    font-size: 18px;
    margin-top: 10px;
  }

  .footer-submenu a,
  .footer-submenu a:hover {
    font-size: 12px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .footer-section .foot-title {
    font-size: 18px;
    margin-top: 10px;
  }

  .footer-submenu a,
  .footer-submenu a:hover {
    font-size: 12px;
  }
}

@media (min-width: 993px) and (max-width: 1024px) {
  .footer-section .foot-title {
    font-size: 17px;
    margin-top: 10px;
  }

  .footer-submenu a,
  .footer-submenu a:hover {
    font-size: 12px;
  }

  .feature-fafa-subtext {
    margin: 3px 0px 0px 5px;
    font-size: 12px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .footer-section .foot-title {
    font-size: 17px;
    margin-top: 10px;
  }

  .footer-submenu a,
  .footer-submenu a:hover {
    font-size: 12px;
  }

  .feature-fafa-subtext {
    margin: 3px 0px 0px 5px;
    font-size: 12px;
  }
}
