.privacy-policy {
    margin: 5% 0;
}

.privacy-policy .heading-holder h5 {
    font-size: 25px;
    font-weight: bold;
}

.privacy-policy .heading-holder p {
    font-size: 15px;
    text-align: justify;
}

.privacy-policy .heading-holder h6 {
    font-size: 18px;
    font-weight: 600;
}

.privacy-policy .heading-holder h4 {
    font-size: 16px;
    font-weight: 500;

}

.privacy-policy .heading-holder span {
    font-size: 15px;
    font-weight: 600;
}

@media(min-width:0px) and (max-width:991px) {
    .privacy-policy .heading-holder h5 {
        font-size: 20px;
        text-align: center;
    }

    .privacy-policy .heading-holder h6 {
        font-size: 17px;
    }
}