.Home-Banner img.Banner-image {
  height: 100%;
  width: 100%;
  margin-top: 2%;
  /* object-fit: cover; */
  border-radius: 30px;
}


.Home-Banner  .vdo-holder{
    width: 100%;
    height: 490px;
    cursor: pointer;

}

.Home-Banner {
  position: relative;
}
.Home-Banner .home-nav-tab-mobile {
  display: none;
}
@media (min-width: 0px) and (max-width: 320px) {
  .Home-Banner .home-nav-tab-mobile {
    display: block;
  }
  .Home-Banner img.Banner-image {
    border-radius: 11px;
}


.Home-Banner  .vdo-holder {
  width: 100%;
  height: 91px;
}

  /* .Home-Banner img.Banner-image {
    height: 430px;
  } */
}
@media (min-width: 321px) and (max-width: 485px) {
  .Home-Banner .home-nav-tab-mobile {
    display: block;
  }
  .Home-Banner  .vdo-holder {
    width: 100%;
    height: 143px;
}
  .Home-Banner img.Banner-image {
    border-radius: 13px;
}

  /* .Home-Banner img.Banner-image {
    height: 430px;
  } */
}
@media (min-width: 486px) and (max-width: 576px) {
  .Home-Banner .home-nav-tab-mobile {
    display: block;
  }

  .Home-Banner  .vdo-holder {
    width: 100%;
    height: 167px;
}
  .Home-Banner img.Banner-image {
    border-radius: 18px;
}

  /* .Home-Banner img.Banner-image {
    height: 430px;
  } */
}


@media (min-width: 577px) and (max-width: 768px) {
  .Home-Banner .home-nav-tab-mobile {
    display: block;
  }

  .Home-Banner  .vdo-holder {
    width: 100%;
    height: 250px;
}
  .Home-Banner img.Banner-image {
    border-radius: 18px;
}

  /* .Home-Banner img.Banner-image {
    height: 430px;
  } */
}
@media (min-width: 769px) and (max-width: 992px) {
  .Home-Banner img.Banner-image {
    border-radius: 20px;
}
.Home-Banner  .vdo-holder {
  width: 100%;
  height: 250px;
}
  /* .Home-Banner img.Banner-image {
    height: 454px;
  } */
}
@media (min-width: 992px) and (max-width: 1200px) {
  .Home-Banner img.Banner-image {
    border-radius: 23px;
}

.Home-Banner  .vdo-holder {
  width: 100%;
  height: 355px;
}
  /* .Home-Banner img.Banner-image {
    height: 454px;
  } */
}
@media (min-width: 1201px) and (max-width: 1399px) {
.Home-Banner  .vdo-holder {
  width: 100%;
  height: 400px;
}
}