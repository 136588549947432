.binnerbanner-section .bloginner .blogin-img {
  width: 100%;
  height: 100%;
  /* height: 325px; */
  /* object-fit: cover; */
}

@media (min-width: 0px) and (max-width: 767px) {
  /* .binnerbanner-section .bloginner .blogin-img {
    height: 250px;
  } */
}
